
type Located = {
    elem: Element
} | {
    text: Text
    offset: number
}



// supports 
// "T123" 123rd table in doc
// "div_23", 23rd div in doc
// "p_23_12" text element in p (offset 12)
export const locateElem = (location: string): Located | undefined => {
    const $el = document.querySelector("#document-html"); // your root
    if (!$el) return
    if (!location) return
    if (location.includes("_")) {
        const [tag, idx, offsetStr] = location.split("_")
        if (Number(idx) === 0) return
        const elems = $el.querySelectorAll(tag)
        let elem = elems[Number(idx) - 1]

        if (!elem){
            return
        }

        if (!offsetStr) {
            return { elem }
        }
        const offset = Number(offsetStr)
        const found = findTextAndOffset(elem, offset)
        if (!found) {
            // couldn't figure out the offset
            return { elem }
        } else {
            return found
        }

    } else if (location.startsWith("T")) {
        const idx = Number(location.slice(1)) - 1 // locator is 1-indexed
        const tables = $el.querySelectorAll('table')
        return { elem: tables[idx] }
    }
}

// given a Node and offset, figure out the Text node that the offset points to, and the offset within that text node
const findTextAndOffset = (elem: Node, offset: number): { text: Text, offset: number } | undefined => {
    if (!elem) return
    if (elem.nodeType === Node.TEXT_NODE) {
        return { text: elem as Text, offset: offset }
    }
    let currentOffset = 0
    for (const child of elem.childNodes) {
        const contents = child.textContent || ""
        if (contents.length + currentOffset >= offset) {
            return findTextAndOffset(child, offset - currentOffset)
        }
        currentOffset += contents.length
    }
    return undefined
}

export const asLocator = (range: Range): { startLocator: string | undefined, endLocator: string | undefined, startWord: number | undefined, endWord: number | undefined } => {
    const doc = document.querySelector("#document-html")!
    const start = range.startContainer
    const end = range.endContainer
    const startOffset = range.startOffset
    const endOffset = range.endOffset

    const startLocator = elemOffsetToLocator(doc, start, startOffset)
    const endLocator = elemOffsetToLocator(doc, end, endOffset)

    const startWord = wordIndexFinder(doc, start, startOffset)
    const endWord = wordIndexFinder(doc, end, endOffset)

    return { startLocator, endLocator, startWord, endWord }
}

const getAllTextNodes = (elem: Node): Text[] => {
    const textNodes: Text[] = [];
    const walker = document.createTreeWalker(
        elem,
        NodeFilter.SHOW_TEXT,
        null
    );
    let node: Node | null;
    while (true) {
        node = walker.nextNode()
        if (!node) break
        textNodes.push(node as Text);
    }
    return textNodes;
}

const getAllElementsByTagName = (elem: Node, tagName: string): Element[] => {
    const elements: Element[] = [];
    
    const walker = document.createTreeWalker(
        elem,
        NodeFilter.SHOW_ELEMENT,
        {
            acceptNode: (node) => {
                return (node as Element).tagName.toLowerCase() === tagName.toLowerCase()
                    ? NodeFilter.FILTER_ACCEPT 
                    : NodeFilter.FILTER_SKIP;
            }
        }
    );

    let node: Node | null;
    while (true) {
        node = walker.nextNode()
        if (!node) break
        elements.push(node as Element);
    }

    return elements;
}

// given node & offset, figure out the index of the corresponding word in the document
const wordIndexFinder = (doc:Node, node: Node, offset: number) => {
    // get all the text nodes in the document
    const textNodes = getAllTextNodes(doc)

    const found = findTextAndOffset(node, offset)
    if (!found) return
    const {text, offset:textOffset} = found
    let wordIdx = 0
    for (const textNode of textNodes) {
        if (textNode === text) break
        wordIdx += textNode.data.split(/\W+/).filter(Boolean).length
    }

    const inTextNode = text.data.slice(0,textOffset).split(/\W+/).filter(Boolean).length
    return wordIdx + inTextNode
}

const isTag = (elem: Node): elem is Element => {
    return elem instanceof Element
}

const elemLocator = (doc: Node, elem: Node): { elem: Element, idx: number } | undefined => {
    const locatable = locatableElem(elem)
    if (!locatable) return

    if (isTag(locatable)) {
        const idx = idxFor(doc, locatable)
        return { elem: locatable as Element, idx }
    }

}

const locatableElem = (elem: Node) => {
    let ret: Node | null = elem
    while (ret && (
        !isTag(ret) ||
        (isTag(ret) && ret.tagName.includes(":"))
    )) {
        ret = ret.parentNode
    }
    return ret as Element | null
}

const idxFor = (doc: Node, elem: Element | null) => {
    let idx = -1
    if (elem && isTag(elem)) {
        const elems = getAllElementsByTagName(doc, elem.tagName)
        let idx = 0
        while (idx < elems.length) {
            if (elems[idx] === elem) {
                return idx
            }
            idx++
        }
    }
    return idx
}


const elemOffsetToLocator = (doc: Node, textNode: Node, offset: number | undefined) => {
    const located = elemLocator(doc, textNode)
    if (!located) return undefined
    const { elem, idx } = located
    if (!offset) return `${elem.tagName}_${idx + 1}`

    // the located node is a parent of the text node -- the text node could have preceding siblings
    let addedOffset = 0
    for (const child of elem.childNodes) {
        if (child === textNode) break
        addedOffset += child.textContent?.length || 0
    }
    return `${elem.tagName}_${idx + 1}_${offset + addedOffset}`
}


export const figureRangeForLocator = (location: { startLocator: string | undefined, endLocator: string | undefined }): Range | undefined => {
    if (!location.startLocator) return
    const start = locateElem(location.startLocator)
    const finish = location.endLocator ? locateElem(location.endLocator) : undefined

    // when the end locator is specified but can't be located, we cannot proceed.
    if (location.endLocator && !finish) return


    // set the start of the range
    if (!start ) return
    const range = new Range()
    if ("text" in start) {
        range.setStart(start.text, start.offset)
    } else {
        range.setStart(start.elem, 0)
    }

    // set the end of the range

    if (location.endLocator) {
        if ("text" in finish!) {
            range.setEnd(finish!.text, finish!.offset)
        } else {
            range.setEnd(finish!.elem, 0)
        }
    } else {
        // if the end locator is not specified, we set the end of the range to the end of the startLocator node
        if ("text" in start) {
            range.setEndAfter(start.text)
        } else {
            range.setEndAfter(start.elem)
        }
    }
    return range
}

export const asText = (doc:Node) => {
    return doc.textContent || ""
}