import { Flex } from "antd"
import { FormItem } from "../DynamicForm"
import { Located } from "../DynamicForm"


export const FiscalYear = () => {
    return (
        <Located path="">
            <FormItem path="fiscalYear" date={true} />
            <FormItem path="endDate" />
        </Located>
    )
}

export const NEO = () => {
    return (
        <Flex gap={4} className="table-style">
            <Located path="">
                <FormItem path="name" />
                <FormItem path="role" />
                <FormItem path="change" />
            </Located>
        </Flex>
    )
}