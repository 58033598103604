import * as XLSX from "xlsx";
import { compensationComiteeCharterValidationData } from "../data/test";
import { CharterJobResultsForTicker } from "src/api-clients/charlie";

// package results as a spreadsheet.
// should probably live in the backend.

const emitBaseRow = (result : CharterJobResultsForTicker) => {
    const nextBaseDataForResult = {
      "Company Name": result.charter?.company_name || "",
      Ticker: result.ticker,
      Industry: result.charter?.industry || "",
      "Committee Name": result.charter?.committee_name || "",
      "Committee Name (As per Company)":
        result.charter?.committee_name_as_per_company || "",
      "Charter Availability": result.charter?.charter_availability || "",
      "Charter Reviewed Date": result.charter?.charter_reviewed_date || "",
      "Charter Reviewed Date Disclosed":
        result.charter?.charter_reviewed_date_disclosed || "",
      "Charter Document URL":
        result.extractResponse?.results[0]?.charter_document_url || "",
      "Source Page URL":
        result.extractResponse?.results[0]?.source_page_url || "",
      "Is Top-1 Retrieval Success": "❌",
      "Is Top-3 Retrieval Success (Tavily)": "❌",
      "Is Top-3 Retrieval Success (Firecrawl)": "❌",
    };
  
    if (compensationComiteeCharterValidationData[result.ticker]) {
      const { charterDocumentUrl } =
        compensationComiteeCharterValidationData[result.ticker];
  
      nextBaseDataForResult["Is Top-1 Retrieval Success"] =
        nextBaseDataForResult["Charter Document URL"] === charterDocumentUrl
          ? "✅"
          : "❌";
      nextBaseDataForResult["Is Top-3 Retrieval Success (Tavily)"] =
        result.searchResponse?.results
          .slice(0, 3)
          .some((sr) => sr.url === charterDocumentUrl)
          ? "✅"
          : "❌";
      nextBaseDataForResult["Is Top-3 Retrieval Success (Firecrawl)"] =
        result.extractResponse?.results
          .slice(0, 3)
          .some((er) => er.charter_document_url === charterDocumentUrl)
          ? "✅"
          : "❌";
    }
  
    return nextBaseDataForResult;
  }
  
  const emitSearchRows = (result: CharterJobResultsForTicker) =>
    result.searchResponse?.results.slice(0, 3).map((sr, index) => ({
      Ticker: result.ticker,
      "Search Rank": index + 1,
      "Search Title": sr.title,
      "Search URL": sr.url,
      "Search Score": sr.score,
      "Search Published Date": sr.publishedDate,
      "Is Charter Document":
        sr.url ===
        compensationComiteeCharterValidationData[result.ticker]
          ?.charterDocumentUrl
          ? "✅"
          : "❌",
    })) || []
  
  const emitExtractedCharterRows = (result: CharterJobResultsForTicker) =>
    result.extractResponse?.results.map((er) => ({
      Ticker: result.ticker,
      "Extracted Company Name": er.company_name || "",
      "Extracted Charter Date": er.charter_date || "",
      "Extracted Charter Document URL": er.charter_document_url || "",
      "Extracted Source Page URL": er.source_page_url || "",
        "Extracted Text Snippet": er.extracted_text_snippet || "",
        "Is Charter Document":
          er.charter_document_url ===
          compensationComiteeCharterValidationData[result.ticker]
            ?.charterDocumentUrl
            ? "✅"
            : "❌",
      })) || []
  
export const produceWorkbook = (results: CharterJobResultsForTicker[]) => {
  
    // Main data: One row per ticker
    const baseDataSheet = results.map((result) => emitBaseRow(result));
  
    // Search Results: Up to 3 search results per ticker
    const searchResultsSheet = results.flatMap(result => emitSearchRows(result));
  
    // Extracted Charters: Only for tickers with extractResponse
    const extractedChartersSheet = results.flatMap(result => emitExtractedCharterRows(result));
  
    const workbook = XLSX.utils.book_new();
    const baseSheet = XLSX.utils.json_to_sheet(baseDataSheet);
    const searchSheet = XLSX.utils.json_to_sheet(searchResultsSheet);
    const extractSheet = XLSX.utils.json_to_sheet(extractedChartersSheet);
  
    XLSX.utils.book_append_sheet(workbook, baseSheet, "Charter Lookup Results");
    XLSX.utils.book_append_sheet(
      workbook,
      searchSheet,
      "Search Results (Tavily)"
    );
    XLSX.utils.book_append_sheet(
      workbook,
      extractSheet,
      "Extracted Charters (Firecrawl)"
    );
  
    return workbook;
  }
  