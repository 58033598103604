import { FormItem, Located } from "../../DynamicForm"
import { Flex } from "antd"

export const SummaryCompensationTable = () => {
    return (

            <Flex gap={4} className="table-style">
                {/* <Located path="year" noEdit>
                    <FormItem path="year.value" date label="Year" />
                </Located> */}
                <Located path="salary" noEdit>
                    <FormItem path="salary.value" label="Salary" />
                </Located>
                <Located path="bonus" noEdit>
                    <FormItem path="bonus.value" label="Bonus" />
                </Located>  
                <Located path="stockAwards" noEdit>
                    <FormItem path="stockAwards.value" label="Stock Awards" />
                </Located>
                <Located path="optionAwards" noEdit>
                    <FormItem path="optionAwards.value" label="Option Awards" />
                </Located>
                <Located path="nonEquityIncentivePlanComp" noEdit>
                    <FormItem path="nonEquityIncentivePlanComp.value" label="NEIP Comp" />
                </Located>
                <Located path="changeInPensionValue" noEdit>
                    <FormItem path="changeInPensionValue.value" label="Change in Pension Value" />
                </Located>
                <Located path="nonQualifiedDeferredCompensation" noEdit>
                    <FormItem path="nonQualifiedDeferredCompensation.value" label="Non-Qualified Deferred Comp." />
                </Located>
                <Located path="allOtherCompensation" noEdit>
                    <FormItem path="allOtherCompensation.value" label="All Other Compensation" />
                </Located>
                <Located path="totalCompensation" noEdit>
                    <FormItem path="totalCompensation.value" label="Total Compensation" />
                </Located>
            </Flex>    
    )
}
