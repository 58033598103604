import Markdown from "markdown-to-jsx";
import { useMutation } from "@tanstack/react-query";
import { Button, Collapse, Form, Input } from "antd";
import { useState, useEffect } from "react";
import { sendChatMessage, getDocStatus, getFiling } from "../../api-clients/rag";
import { Page } from "../../components/Page";
import { calculateCosineSimilarity } from "./textSimilarity";
import { usePostHog } from "src/hooks/usePostHog";

export function EvalAppPage() {
  const POSTHOG_KEY = "phc_rHnGzEdX4Mj6wmNC2rosFeWD9rHejBV6oZsugWvduvB"
  usePostHog(POSTHOG_KEY)

  
  return (
    <Page>
      <EvalApp />
    </Page>
  );
}

function EvalApp() {
  const [result, setResult] = useState<any>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [similarity, setSimilarity] = useState<number | null>(null);
  const [form] = Form.useForm();

  useEffect(() => {
    const defaultPrompt = `Answer the following question based on the information provided in the SEC filing context:
{{question}}

To answer this question, follow these steps:

1. Carefully analyze the SEC filing context to find relevant information.
2. Organize the information you find in a logical manner.
3. Prepare your answer based on the available data.

When formulating your answer, adhere to these guidelines:

1. Use only the information provided in the SEC filing context above.
2. If the question cannot be answered based on the given context, state that the information is not available.
3. When presenting numerical data, format it in a tabular format where relevant. Use markdown syntax to create tables.
4. Provide clear and concise explanations.
5. If appropriate, break down your answer into relevant sections for clarity.
6. Do not round numbers.
7. If possible, include the line numbers used in the text, for example (line 18) or (lines 10-18)`;

    form.setFieldsValue({
      prompt: defaultPrompt
    });
  }, [form]);

  const askQuestion = useMutation({
    mutationFn: async (values: {
      ticker: string;
      form: string;
      year: number;
      question: string;
      prompt: string;
      idealAnswer?: string;
    }) => {
      try {
        console.log('Starting document status check...');
        // First check document status
        const docStatus = await getDocStatus({
          ticker: values.ticker.toUpperCase(),
          form: values.form.toUpperCase(),
          year: values.year
        });
        console.log('Document status:', docStatus);

        // If document isn't ready, process it first
        if (docStatus.status !== "ready") {
          console.log('Document not ready, starting processing...');
          setIsProcessing(true);
          // Process document by requesting it - this will trigger RAG pipeline
          await getFiling({
            ticker: values.ticker.toUpperCase(),
            form: values.form.toUpperCase(),
            year: values.year
          });

          // Poll until document is ready
          while (true) {
            await new Promise(resolve => setTimeout(resolve, 2500));
            const status = await getDocStatus({
              ticker: values.ticker.toUpperCase(),
              form: values.form.toUpperCase(),
              year: values.year
            });
            console.log('Polling status:', status);
            if (status.status === "ready") break;
          }
          setIsProcessing(false);
        }

        console.log('Sending chat message...');
        // Now send the chat message
        const response = await sendChatMessage(
          values.question,
          [{
            ticker: values.ticker.toUpperCase(),
            form: values.form.toUpperCase(),
            year: values.year
          }],
          [values.prompt] // Wrap the prompt in an array
        );
        console.log('Chat response:', response);
        return response;
      } catch (error) {
        console.error('Error in mutation:', error);
        throw error;
      }
    },
    onError: (error) => {
      console.error('Mutation error:', error);
      // You might want to add some error UI feedback here
    },
    onSuccess: (data) => {
      console.log('Mutation succeeded with data:', data);
      setResult(data);

      // Only calculate similarity if idealAnswer exists
      if (askQuestion.variables?.idealAnswer && data.answer) {
        const similarityScore = calculateCosineSimilarity(data.answer, askQuestion.variables.idealAnswer);
        setSimilarity(similarityScore);
      } else {
        setSimilarity(null);
      }
    },
  });

  const onSubmit = (values: any) => {
    console.log('Form submitted with values:', values);
    // Transform form values before sending
    const transformedValues = {
      ...values,
      ticker: values.ticker.toUpperCase(),
      form: values.form.toUpperCase(),
    };
    console.log('Transformed values:', transformedValues);
    askQuestion.mutate(transformedValues);
  };

  return (
    <div style={{ height: "100%", overflow: "auto" }}>
      <h3>Evaluation Tool</h3>

      {isProcessing && (
        <div style={{ marginBottom: 16, color: 'blue' }}>
          Processing document... This may take a few minutes.
        </div>
      )}

      <Form form={form} onFinish={onSubmit} layout="vertical">
        <Form.Item
          name="ticker"
          label="Ticker"
          rules={[{ required: true, message: 'Please input the ticker!' }]}
        >
          <Input placeholder="Enter ticker" />
        </Form.Item>

        <Form.Item
          name="form"
          label="Form"
          rules={[{ required: true, message: 'Please input the form!' }]}
        >
          <Input placeholder="Enter form" />
        </Form.Item>

        <Form.Item
          name="year"
          label="Year"
          rules={[{ required: true, message: 'Please input the year!' }]}
        >
          <Input type="number" placeholder="Enter year" />
        </Form.Item>

        <Form.Item
          name="prompt"
          label="User Question Prompt"
          rules={[{ required: true, message: 'Please input the prompt!' }]}
        >
          <Input.TextArea placeholder="Enter your prompt" />
        </Form.Item>

        <Form.Item
          name="question"
          label="Question"
          rules={[{ required: true, message: 'Please input the question!' }]}
        >
          <Input.TextArea placeholder="Enter your question" />
        </Form.Item>

        <Form.Item
          name="idealAnswer"
          label="Ideal Answer"
          rules={[{ required: false }]}  // Change this from required: true
        >
          <Input.TextArea placeholder="Enter the ideal answer for comparison (optional)" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={askQuestion.isPending}>
            Submit
          </Button>
        </Form.Item>
      </Form>

      {result && (
        <div style={{ marginTop: 20 }}>
          <Collapse
            items={[
              {
                key: "1",
                label: "Result",
                children: (
                  <div>
                    <div className="response-markdown">
                      <Markdown>{result.answer}</Markdown>
                    </div>
                    {similarity !== null && (
                      <div style={{ marginTop: 10 }}>
                        <div style={{ fontWeight: 'bold' }}>
                          Similarity Score: {(similarity * 100).toFixed(2)}%
                        </div>
                      </div>
                    )}
                  </div>
                ),
              },
            ]}
          />
        </div>
      )}
    </div>
  );
}