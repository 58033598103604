import { usePostHog } from "src/hooks/usePostHog";
import { Page } from "../../components/Page";
import { ViewerScreen } from "./screens/ViewerScreen";

export function DevViewerPage() {
  const POSTHOG_KEY = "phc_rHnGzEdX4Mj6wmNC2rosFeWD9rHejBV6oZsugWvduvB"
  usePostHog(POSTHOG_KEY)

  
  return (
    <Page>
      <ViewerScreen />
    </Page>
  );
}
