import { FormItem, Located } from "../../DynamicForm"
import { Flex } from "antd"

export const PlanBasedAwardsTable = () => {
    return (

            <Flex gap={4} className="table-style">
                {/* <Located path="year" noEdit>
                    <FormItem path="year.value" date label="Year" />
                </Located> */}
                <Located path="planName" noEdit>
                    <FormItem path="planName.value" label="Plan Name" />
                </Located>
                <Located path="grantDate" noEdit>
                    <FormItem path="grantDate.value" label="Grant Date" date={true}  />
                </Located>  
                <Located path="neipThreshold" noEdit>
                    <FormItem path="neipThreshold.value" label="NEIP Threshold" />
                </Located>
                <Located path="neipTarget" noEdit>
                    <FormItem path="neipTarget.value" label="NEIP Target" />
                </Located>
                <Located path="neipMaximum" noEdit>
                    <FormItem path="neipMaximum.value" label="NEIP Maximum" />
                </Located>
                <Located path="eipThreshold" noEdit>
                    <FormItem path="eipThreshold.value" label="EIP Threshold" />
                </Located>
                <Located path="eipTarget" noEdit>
                    <FormItem path="eipTarget.value" label="EIP Target" />
                </Located>
                <Located path="eipMaximum" noEdit>
                    <FormItem path="eipMaximum.value" label="EIP Maximum" />
                </Located>
                <Located path="allOtherStockAwards" noEdit>
                    <FormItem path="allOtherStockAwards.value" label="All Other Stock Awards" />
                </Located>
                <Located path="allOtherOptionAwards" noEdit>
                    <FormItem path="allOtherOptionAwards.value" label="All Other Option Awards" />
                </Located>
                <Located path="optionExercisePrice" noEdit>
                    <FormItem path="optionExercisePrice.value" label="Option Exercise Price" />
                </Located>
                <Located path="grantDateFairValue" noEdit>
                    <FormItem path="grantDateFairValue.value" label="Grant Date Fair Value" />
                </Located>
            </Flex>    
    )
}
