import { Flex, Button, Select, theme } from "antd";
import { useContext, useState } from "react";
import { TickersSelect } from "../../../components/TickersSelect";
import { CharlieContext } from "../CharlieContext";
import { CHARTER_TYPES, CharterType } from "../../../api-clients/charlie";

export function CharlieStartScreen() {
  const { token } = theme.useToken();
  const context = useContext(CharlieContext);
  const [selectedTickers, setSelectedTickers] = useState<string[]>([]);
  const [charterType, setCharterType] = useState<CharterType | null>(null);

  const handleSubmit = () => {
    if (selectedTickers.length === 0 || !charterType) {
      console.warn("Please select tickers and a charter type");
      return;
    }
    context.process({
      tickers: selectedTickers,
      charterType,
    });
  };

  return (
    <Flex justify="center">
      <div
        style={{
          marginTop: 100,
          borderRadius: 15,
          padding: 15,
          paddingBottom: 25,
          backgroundColor: token.colorBgContainer,
          boxShadow: token.boxShadowTertiary,
        }}
      >
        <div style={{ marginBottom: 12, width: 420 }}>
          Start by selecting a list of tickers and a charter type.
        </div>

        <Select
          showSearch
          placeholder="Select charter type"
          optionFilterProp="label"
          options={CHARTER_TYPES}
          style={{ width: "100%", marginTop: 10, marginBottom: 10 }}
          onChange={(value) => setCharterType(value)}
        />

        <TickersSelect
          onSelectionChange={(tickers) => setSelectedTickers(tickers)}
        />

        <Button
          type="primary"
          onClick={handleSubmit}
          style={{ marginTop: 15, width: "100%" }}
          disabled={selectedTickers.length === 0 || !charterType}
        >
          Collect Charters
        </Button>
      </div>
    </Flex>
  );
}
