import Markdown from "markdown-to-jsx";
import { useUser } from "@clerk/clerk-react";
import { AnchorHTMLAttributes, PropsWithChildren, useEffect, useState } from "react";
import { useClipboard } from "@custom-react-hooks/use-clipboard";
import { Avatar, Button, Flex, Space, theme, Tooltip } from "antd";
import { CheckOutlined, CopyOutlined } from "@ant-design/icons";
import { ChatInstance, UseChatApp } from "./useChatApp";
import { triggerHighlightEvent } from "./useHighlightEvent";

export function UserMessage(props: PropsWithChildren) {
  const { user } = useUser();
  const { token } = theme.useToken();
  return (
    <Flex gap={10} align="flex-start">
      <div style={{ paddingTop: 5 }}>
        <Avatar src={user?.imageUrl}>{user?.fullName?.at(0) || ">"}</Avatar>
      </div>
      <div
        style={{
          flex: 1,
          borderRadius: 7,
          backgroundColor: token.colorBgLayout,
          padding: "10px 15px",
          marginBottom: 7,
        }}
      >
        {props.children}
      </div>
    </Flex>
  );
}

export function ResponseMessage(
  props: PropsWithChildren<{
    messageIndex?: number;
    convo?: ChatInstance;
    feedback?: UseChatApp["feedback"];
  }>
) {
  const { token } = theme.useToken();
  const feedbackMessage = props.convo?.messages.at(props.messageIndex ?? 0);
  const rating = feedbackMessage?.feedback?.rating;
  const hasRating = rating !== undefined;
  const { copyToClipboard } = useClipboard();
  const [copied, setCopied] = useState(false);

  return (
    <div>
      <div
        style={{
          borderRadius: 7,
          backgroundColor: token.colorPrimaryBg,
          padding: "10px 15px 3px",
          marginBottom: 7,
        }}
      >
        {props.children}
        {props.feedback && feedbackMessage && (
          <Flex justify="flex-end" align="center">
            <Space.Compact>
              <Button
                type="text"
                size="small"
                disabled={hasRating}
                onClick={() => {
                  props.feedback?.mutate({
                    rating: 1,
                    messageId: feedbackMessage.feedback?.messageId ?? "",
                    chatIndex: props.convo?.id ?? 0,
                    messageIndex: props.messageIndex ?? 0,
                  });
                }}
              >
                <Tooltip title="Mark as a very good answer">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ transform: "rotate(180deg)" }}
                  >
                    <path
                      d="M14.1666 11.6667V1.66669M7.50002 15.1L8.33335 11.6667H3.47502C3.21627 11.6667 2.96109 11.6064 2.72966 11.4907C2.49823 11.375 2.29693 11.207 2.14168 11C1.98644 10.793 1.88152 10.5527 1.83523 10.2982C1.78895 10.0436 1.80257 9.78175 1.87502 9.53335L3.81668 2.86669C3.91766 2.52049 4.12819 2.21639 4.41668 2.00002C4.70518 1.78365 5.05607 1.66669 5.41668 1.66669H16.6667C17.1087 1.66669 17.5326 1.84228 17.8452 2.15484C18.1578 2.4674 18.3333 2.89133 18.3333 3.33335V10C18.3333 10.442 18.1578 10.866 17.8452 11.1785C17.5326 11.4911 17.1087 11.6667 16.6667 11.6667H14.3667C14.0566 11.6669 13.7527 11.7535 13.4892 11.9169C13.2257 12.0803 13.013 12.314 12.875 12.5917L10 18.3334C9.60704 18.3285 9.22023 18.2349 8.86851 18.0595C8.51679 17.8842 8.20924 17.6316 7.96885 17.3207C7.72845 17.0098 7.56142 16.6486 7.48024 16.264C7.39905 15.8795 7.40581 15.4816 7.50002 15.1Z"
                      stroke="currentColor"
                      fill={rating === 1 ? "currentColor" : undefined}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Tooltip>
              </Button>
              <Button
                type="text"
                size="small"
                disabled={hasRating}
                onClick={() => {
                  props.feedback?.mutate({
                    rating: -1,
                    messageId: feedbackMessage.feedback?.messageId ?? "",
                    chatIndex: props.convo?.id ?? 0,
                    messageIndex: props.messageIndex ?? 0,
                  });
                }}
              >
                <Tooltip title="Mark as inaccurate or wrong">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.1666 11.6667V1.66669M7.50002 15.1L8.33335 11.6667H3.47502C3.21627 11.6667 2.96109 11.6064 2.72966 11.4907C2.49823 11.375 2.29693 11.207 2.14168 11C1.98644 10.793 1.88152 10.5527 1.83523 10.2982C1.78895 10.0436 1.80257 9.78175 1.87502 9.53335L3.81668 2.86669C3.91766 2.52049 4.12819 2.21639 4.41668 2.00002C4.70518 1.78365 5.05607 1.66669 5.41668 1.66669H16.6667C17.1087 1.66669 17.5326 1.84228 17.8452 2.15484C18.1578 2.4674 18.3333 2.89133 18.3333 3.33335V10C18.3333 10.442 18.1578 10.866 17.8452 11.1785C17.5326 11.4911 17.1087 11.6667 16.6667 11.6667H14.3667C14.0566 11.6669 13.7527 11.7535 13.4892 11.9169C13.2257 12.0803 13.013 12.314 12.875 12.5917L10 18.3334C9.60704 18.3285 9.22023 18.2349 8.86851 18.0595C8.51679 17.8842 8.20924 17.6316 7.96885 17.3207C7.72845 17.0098 7.56142 16.6486 7.48024 16.264C7.39905 15.8795 7.40581 15.4816 7.50002 15.1Z"
                      stroke="currentColor"
                      fill={rating === -1 ? "currentColor" : undefined}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Tooltip>
              </Button>
              <Button
                type="text"
                size="small"
                onClick={() => {
                  copyToClipboard(feedbackMessage.content);
                  setCopied(true);
                }}
              >
                <Tooltip title="Copy answer to clipboard">
                  {copied ? <CheckOutlined /> : <CopyOutlined />}
                </Tooltip>
              </Button>
            </Space.Compact>
          </Flex>
        )}
      </div>
    </div>
  );
}

export function MarkdownResponse({
  message,
  sections,
  ...props
}: PropsWithChildren<{
  messageIndex?: number;
  convo?: ChatInstance;
  feedback?: UseChatApp["feedback"];
  message: string;
  sections?: Array<{
    title: string;
    body: string;
    locator?: { startLocator?: string; endLocator?: string };
  }>;
}>) {
  useEffect(() => {
    if (!sections?.length) return;
    const firstWithLocator = sections.find(
      (s) => s.locator?.startLocator && s.locator.endLocator
    );
    if (firstWithLocator) {
      triggerHighlightEvent({
        start: firstWithLocator.locator!.startLocator!,
        end: firstWithLocator.locator!.endLocator!,
      });
    }
  }, [sections]);

  if (sections && sections.length) {
    return (
      <ResponseMessage {...props}>
        <div className="response-markdown">
          {sections.map((section, idx) => (
            <div key={idx} style={{ marginBottom: "1rem" }}>
              <Flex align="center" gap={5}>
              <h3 style={{ margin: 0 }}>{section.title}</h3>
                {section.locator?.startLocator && section.locator.endLocator && (
                  <LineButton
                    startLocator={section.locator.startLocator}
                    endLocator={section.locator.endLocator}
                  >
                    View Reference
                  </LineButton>
                )}
              </Flex>
              <Markdown>{section.body}</Markdown>
            </div>
          ))}
        </div>
      </ResponseMessage>
    );
  }

  return (
    <ResponseMessage {...props}>
      <div className="response-markdown">
        <Markdown
          options={{
            overrides: {
              a: {
                component: LineButton,
              },
            },
          }}
        >
          {message}
        </Markdown>
      </div>
    </ResponseMessage>
  );
}

interface LineButtonProps extends PropsWithChildren<AnchorHTMLAttributes<HTMLAnchorElement>> {
  startLocator?: string;
  endLocator?: string;
}
const LineButton = ({
  startLocator,
  endLocator,
  children,
  ...props
}: LineButtonProps) => {
  const { token } = theme.useToken();
  if (startLocator) {
    return (
      <div
        style={{
          backgroundColor: `${token.colorPrimary}13`,
          display: "inline-block",
          borderRadius: 5,
        }}
      >
        <Button
          type="text"
          size="small"
          onClick={() => {
            triggerHighlightEvent({ start: startLocator, end: endLocator || startLocator });
          }}
          style={{ color: token.colorTextSecondary }}
        >
          {children}
        </Button>
      </div>
    );
  }

  return (
    <a {...props}>
      {children}
    </a>
  );
};
