import { Review } from "../../../api-clients/def14a"
import { SOGTarget, SOGQualifying, SOGTimeFrame, SOGWithHolding } from "./cda/sog"
import { CompensationPayout, CompensationTargets, LtiClicTable, Principle } from './cda/lti'
import { Principle as STIPrinciple, CompensationTargets as STICompensationTargets } from './cda/sti'
import { createContext } from 'react'
import { JSONSchema7 } from 'json-schema'
import { Coordinates, DynamicForm } from "../DynamicForm"
import { valueOf } from "../useReviews"
import { FiscalYear, NEO } from "./background"
import { Consultant } from "./cda/consultant"
import { Salary } from "./cda/salary"
import { TotalCash } from "./cda/totalCash"
import { TotalDirectCompensation } from "./cda/totalDirectCompensation"
import { TotalRewards } from "./cda/totalRewards"
import { Clawback } from "./cda/clawback"
import { Notes } from "./cda/notes"
import { Flex } from "antd"
import { SummaryCompensationTable } from "./summaryCompensation/table"
import { CashAndPlanning, Commingled, TravelAndEntertainment, GeneralServices, Retirement, Insurance } from "./summaryCompensation/otherCompensation"
import { PlanBasedAwardsTable } from "./pba/table"
import { PbaMetricsDefinitions, PbaMetricsPerformances } from "./pba/metrics"
import { EipGrant, NeipGrant } from "./pba/grants"

export const DynamicCard = (props: { review: Review, canceling: boolean, onSubmit: (data: any) => void, onChange: (val:any)=> void, onDirty: (val: boolean) => void }) => {

    const ctx = useDatapointContext(props.review, props.canceling, props.onSubmit, props.onChange, props.onDirty)
    const { schema, value } = ctx
    if (!schema) {
        console.log("no schema")
        return <DefaultCard value={value} />
    }

    const key =  ctx.key
    console.log("key", key)
    if (!key) {
        return <DefaultCard value={value} />
    }
    const rendererLookup = {
        "def14a.background.fiscalYear": FiscalYear,
        "def14a.background.neos": NEO,
        "def14a.cda.salary": Salary,
        "def14a.cda.sti.principle": STIPrinciple,
        "def14a.cda.sti.targets": STICompensationTargets,
        "def14a.cda.lti.principle": Principle,
        "def14a.cda.lti.targets": CompensationTargets,
        "def14a.cda.lti.payouts": CompensationPayout,
        "def14a.cda.totalCash": TotalCash,
        "def14a.cda.totalDirectCompensation": TotalDirectCompensation,
        "def14a.cda.totalRewards": TotalRewards,
        "def14a.cda.clawback": Clawback,
        "def14a.cda.sog.target": SOGTarget,
        "def14a.cda.sog.qualifying": SOGQualifying,
        "def14a.cda.sog.timeFrame": SOGTimeFrame,
        "def14a.cda.sog.withHoldingRules": SOGWithHolding,
        "def14a.cda.consultants": Consultant,
        "def14a.cda.notes": Notes,
        "def14a.sct.table": SummaryCompensationTable,
        "def14a.sct.otherCompensation.retirement": Retirement,
        "def14a.sct.otherCompensation.insurance": Insurance,
        "def14a.sct.otherCompensation.cashAndPlanning": CashAndPlanning,
        "def14a.sct.otherCompensation.travelAndEntertainment": TravelAndEntertainment,
        "def14a.sct.otherCompensation.generalServices": GeneralServices,
        "def14a.sct.otherCompensation.commingled": Commingled,
        "def14a.pba.table": PlanBasedAwardsTable,
        "def14a.pba.grants.neip": NeipGrant,
        "def14a.pba.grants.eip": EipGrant,
        // "def14a.pba.metrics": PbaMetrics,
        "def14a.pba.metrics.definitions": PbaMetricsDefinitions,
        "def14a.pba.metrics.performances": PbaMetricsPerformances,

        
    } as const

    const Renderer = rendererLookup[key as keyof typeof rendererLookup]
    if (!Renderer) {
        return <>
            missing renderer for {key}
            <DefaultCard value={value} />
        </>
    }
    return (
        <datapointContext.Provider value={ctx}>
            <DynamicForm>
                <Flex gap={4} vertical={false} className="dynamic-card" align="flex-end">
                    <Coordinates />
                    <Renderer/>
                </Flex>
            </DynamicForm>
        </datapointContext.Provider>
    )
}

const useDatapointContext = (review: Review, canceling: boolean, onSubmit: (review: Review) => void, onChange: (val:any)=> void, onDirty: (val: boolean) => void) => {
    const definitions = review.reviewed.item.schema.definitions || {}
    const key = Object.keys(definitions)[0]
    const schema = definitions[key] as JSONSchema7
    const value = valueOf(review)
    const coordinates = review.datapointCoordinates
    return { schema, value, onSubmit, onDirty, key, canceling, onChange, coordinates }
}

type UseDatapointContext = ReturnType<typeof useDatapointContext>
export const datapointContext = createContext(undefined as unknown as UseDatapointContext)



const DefaultCard = (props: { value: any }) => {
    return (
        <pre>
            {JSON.stringify(props.value, null, 2)}
        </pre>
    )
}

// code for presenting the datapoints as a table
// the intention is to provide a concise way of reviewing the data before submitting it to clic
// this code is currently disabled -- until we
// - have a clear idea of what exactly the right review format is
// - start working on integrating with clic
export const hasTableView = (subsection: string ) => {
    // return subsection in tableLookup
    return false
}

// preliminary work that has been done
const tableLookup = {
    "def14a.cda.lti": LtiClicTable,
    // "def14a.cda.sog": SogClicTable,
}


export const ClicTable = (props: {reviews: Review<any>[]}) => {
    const subsections = props.reviews.map(r => r.datapointNamespace.split(".").slice(0, -1).join("."))
    const uniqueSubsections = [...new Set(subsections)]
    if (uniqueSubsections.length === 0) return <></>
    if (uniqueSubsections.length > 1) return <>multiple subsections not supported</>
    const subsection = uniqueSubsections[0]


    const Table = tableLookup[subsection as keyof typeof tableLookup]
    if (!Table) return <></>

    return (<Table {...props} />)
}
