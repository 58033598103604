import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Extraction, extractReviewFromFragment, Fragment, getCLICView, getReviews, Review, submitReview, submitToCLIC } from "../../api-clients/def14a";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { defaultNamespace } from "./Minimap";
import { loggerContext, useLoggerContext } from "./useLogger";
import { useSearchParams } from "react-router-dom";

export type UseReviews = ReturnType<typeof useReviews>
export const reviewsContext = createContext(undefined as unknown as UseReviews)


// a review may have several locators -- recursively figure the first one
export const firstLocation = (result: any): {startLocator: string, endLocator: string, startWord: number, endWord: number} | undefined =>{
  if (result === undefined || result === null) return undefined
  if (Array.isArray(result)) {
    for (const item of result) {
      const r = firstLocation(item)
      if (r) return r
    }
    return undefined
  }

  if (typeof result === "object") {
    // if has referenceText, we are done
    if ("referenceText" in result) {
      if (!result.location) return undefined
      const {startLocator, endLocator, startWord, endWord} = result.location
      return {startLocator, endLocator, startWord, endWord}
    }

    // recurse
    for (const key in result) {
      const r = firstLocation(result[key])
      if (r) return r
    }
  }
  return undefined
}

export const valueOf = (review: Review) => {
  return review.assessment === "corrected" ? review.result : review.reviewed.item.value
}
  
export function useReviews(
  doc: {
    ticker: string;
    year: number;
    form: string;
    accessionNumber: string;
  } | undefined) {

  const [searchParams, setSearchParams] = useSearchParams();
  const [navLocator, setNavLocator] = useState<string>(() => {
    const navSection = searchParams.get("navSection");
    return navSection || defaultNamespace() || "def14a";
  });

  // Update URL when navLocator changes
  useEffect(() => {
    const currentParams = Object.fromEntries(searchParams.entries());
    setSearchParams({ ...currentParams, navSection: navLocator });
  }, [navLocator, setSearchParams, searchParams]);

  // TODO the order of the reviews must be fixed (e.g. doc index would be great)
  const query = useQuery({
    queryKey: ["reviews", doc],
    queryFn: async () => {
      const {reviews, extraction} = await getReviews(doc!)
      try{
        reviews.sort((r1,r2)=> {
          const l1 = firstLocation(valueOf(r1))
          const l2 = firstLocation(valueOf(r2))
          if (l1 && l2){
            return ( l1.startWord || 0) - (l2.startWord || 0)
          }
          if (l1) return -1
          if (l2) return 1        
          return 0
        })

      }catch(e){
        console.error("error sorting reviews", e)
      }
      return {reviews, extraction}
    }
  });

  const [reviewFilter, setReviewFilter] = useState<string>("all")

  const inScope = (reviews: Review[], scope:string)=>{
    if (scope === "all") return reviews
    return reviews.filter(r=>r.assessment === scope)
  }

  const reviews = useMemo(()=>{
    return query.data?.reviews || []
  },[query.data])

  const situationStats = useMemo(()=>{
    const parts = navLocator.split(".")
    const section = parts.slice(0, 2).join(".")
    const subsection = parts.slice(0, 3).join(".")
    const item = navLocator;

    const statsFor = (namespace:string)=>{  
      const total = reviews.filter(r=>r.datapointNamespace.startsWith(namespace))
      const count = inScope(total, reviewFilter)
      return {namespace, total: total.length, count: count.length}
    }

    const stats = [
      statsFor(section),
      statsFor(subsection),
      statsFor(item)
    ]

    return stats
  },[reviews, navLocator, reviewFilter])


  const reviewsInNav = useMemo(()=>{    
    const locatorParts = navLocator.split(".").length
    return reviews.filter(r=>r.datapointNamespace.split(".").slice(0, locatorParts).join(".") === navLocator)
  },[reviews, navLocator])

  const inSubsectionScope = useMemo(()=>{
    const parts = navLocator.split(".")
    const subsection = parts.slice(0, 3).join(".")
    return inScope(reviews.filter(r => r.datapointNamespace.startsWith(subsection)), reviewFilter)
  },[reviews, navLocator, reviewFilter])

  const reviewsInScope = useMemo(() => {
    return inScope(reviewsInNav, reviewFilter)
  }, [reviewsInNav, reviewFilter])

  const [currentReview, setCurrentReview] = useState<Review | undefined>()

  useEffect(() => {
    if ((reviewsInScope || []).length === 0) {
      setCurrentReview(undefined)
      return
    }
    if (currentReview) {
      // if the item still in scope, do nothing
      if (reviewsInScope?.find(r => r.id === currentReview.id)) return
      // find the next item that is in scope. if none, fall through
      const idx = reviewsInNav.findIndex(r => r.id === currentReview.id)
      if (idx > -1) {
        const next = reviewsInNav.slice(idx + 1).find(r => reviewsInScope.find(rs => rs.id === r.id))
        if (next) {
          setCurrentReview(next)
          return
        }
      }
    }
    // fallback
    setCurrentReview(reviewsInScope[0])
  }, [currentReview, reviewsInScope, reviewsInNav])


  const assess = async (review: Review) => {
    await submitReview(review)
    return review
  }
  const queryClient = useQueryClient();

  const mutateReview = useMutation({
    mutationFn: assess,
    // onSettled: () => {
    //   queryClient.invalidateQueries({ queryKey: ["reviews", doc] })
    // },
    // onSuccess: () => {
    //   console.log("invalidating reviews")
    //   queryClient.invalidateQueries({ queryKey: ["reviews", doc] })
    // },

    onMutate: async (newReview) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ['reviews', doc] })
  
      // Snapshot the previous value
      const previousValue = queryClient.getQueryData<{reviews: Review[], extraction: Extraction}>(['reviews', doc])

      const previousReviews = previousValue?.reviews || []
      const newReviews = previousReviews.map(r=>r.id === newReview.id ? newReview : r)
  
      const newValue = {...previousValue, reviews: newReviews}
      // Optimistically update to the new value
      queryClient.setQueryData(['reviews', doc], newValue)
  
      // Return a context with the previous and new reviews
      return { previousValue, newValue }
    },
    // If the mutation fails, use the context we returned above
    onError: (err, newReview, context) => {
      queryClient.setQueryData(
        ['reviews', doc],
        context!.previousValue,
      )
    },
    // Always refetch after error or success:
    onSettled: (newReview) => {
      queryClient.invalidateQueries({ queryKey: ['reviews', doc] })
    },
  })

  const {log} = useContext(loggerContext)

  const accept = async (review: Review) => {
    mutateReview.mutate({ ...review, assessment: "accepted" })
    log("accept", {review_id: review.id})
  }
  const correct = async (review: Review) => {
    mutateReview.mutate({ ...review, assessment: "corrected" })
    log("accept", {review_id: review.id})
  }

  const reject = async (review: Review) => {
    mutateReview.mutate({ ...review, assessment: "rejected" })
    log("accept", {review_id: review.id})
  }

  const [extracting, setExtracting] = useState(false)
  
  const extractFromFragment = useCallback(async (datapointNamespace: string, selection: Fragment) => {
    log("create", {datapointNamespace, selection})

    const extraction = query.data?.extraction
    if (!extraction) return
    const {id:extractionId, ticker, accessionNumber} = extraction

    setExtracting(true)
    const newReviews = await extractReviewFromFragment({ticker, accessionNumber}, extractionId, datapointNamespace, selection)   
    if (newReviews.length >= 0) {
      // update the query data
      queryClient.setQueryData(['reviews', doc], {
        ...query.data,
        reviews: [...newReviews, ...query.data!.reviews, ]
      })
    }
    setExtracting(false)
    return newReviews.length
  }, [doc, log, query.data, queryClient]
)

// set the logger context
  useLoggerContext('section', navLocator)

  const sendToCLIC = useCallback(async () => {
    const extraction = query.data?.extraction
    const datapointNamespace = reviewsInScope[0]?.datapointNamespace
    if (!extraction) return    
    const {id:extractionId, ticker, accessionNumber} = extraction
    const data = await submitToCLIC({ticker, accessionNumber}, extractionId, datapointNamespace)
    return data
  }, [query.data?.extraction, reviewsInScope])

  const asClicRows = useQuery({
    queryKey: ["clicView", doc, reviewsInScope],
    queryFn: async () => {
      const datapointNamespace = reviewsInScope[0]?.datapointNamespace
      if (!datapointNamespace) return []
      const {id:extractionId, ticker, accessionNumber} = query.data?.extraction || {}
      if (!extractionId || !ticker || !accessionNumber) return []
      const data = await getCLICView({ticker, accessionNumber}, extractionId, datapointNamespace)
      return data
    }
  })

  return {
    sendToCLIC,
    asClicRows,
    ready: query.isFetched,
    data: reviewsInScope,
    reviewsInNav,
    inSubsectionScope,
    situationStats,
    navLocator,
    setNavLocator,
    reviewFilter,
    setReviewFilter,
    
    accept,
    reject,
    correct,
    // process a doc fragment
    extractFromFragment,
    // are we currently extracting a review from a fragment
    extracting,
  };
}

