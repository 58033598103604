import { usePostHog } from "src/hooks/usePostHog";
import { Page } from "../../components/Page";
import { InputScreen } from "./screens/input";
import { ViewerScreen } from "./screens/view";
import { useApp } from "./useApp";
export function Dev8k502Page() {

  const context = useApp()
  const hasDoc = context.doc!!

  const POSTHOG_KEY = "phc_rHnGzEdX4Mj6wmNC2rosFeWD9rHejBV6oZsugWvduvB"
  usePostHog(POSTHOG_KEY)

  return (
    <Page>
      {hasDoc ? <ViewerScreen/> : <InputScreen/>}
    </Page>
  );
}
