import { useContext, useState } from "react"
import { appContext } from "../useApp"
import { Table, Popover, Button } from "antd"

import { Located } from "./types";
import { renderApplicableTo, RenderLocated } from "./common";
import { LTITargets, LTIPrinciple, LTIPayouts } from "@chester/shared/def14a/cda/lti"

type Kind = string
type Value = number

type LTIClicRow = {
    ticker?: string,
    name?: string,
    fyend?: string,
    title?: string,
    role?: string,
    type: Kind,
    peerGroup?: Value,
    peerGroupLow?: Value,
    peerGroupHigh?: Value,
    peerGroupMedianPercent?: Value,
    peerGroupMedianPercentLow?: Value,
    peerGroupMedianPercentHigh?: Value,
    salary?: Value,
    salaryLow?: Value,
    salaryHigh?: Value,
    dollar?: Value,
    dollarLow?: Value,
    dollarHigh?: Value,

    payoutThreshold?: Value,
    payoutMaximum?: Value,

}

type LTI = {
    levels: LTITargets[],
    principle: LTIPrinciple[],
    payouts: LTIPayouts[]
    asClic: LTIClicRow[]
}

const figureLocations = (data: LTI) => {
    if (!data) return []
    return  [
        ...data.principle.map(p => p.peerGroupTarget),
        ...data.levels.map(level => level.compensationTarget),
        ...data.payouts.map(payout => payout.rule)
    ]
        .filter(d => d && "location" in d)
        .map(d => (d as Record<string, any>).location)
        .filter(Boolean) as Located["location"][]
}

const LtiResults = ({ data }: { data: LTI }) => {
    const [open, setOpen] = useState<boolean>(false)
    return (<>
    principle
        <LtiPrinciple data={data.principle} />
    targets
        <CompensationTargetTable data={data.levels} />
    payouts
        <CompensationPayoutsTable data={data.payouts} />

    <br/>
    <br/>
    <br/>
        <Popover open={open} onOpenChange={()=> setOpen(open => !!open)} content={<ClicTable data={data.asClic} />}>
            <Button onClick={() => setOpen(!open)}>as Clic table</Button>
        </Popover>
       
    </>)
}

const ClicTable = ({data}: {data: LTIClicRow[]}) => {

    const datasource  = data 

    const columns= [
        {
            title: "Role",
            dataIndex: "role",
            key: "role",
            className: "clic-dark"
        }, 
        {
            title: "Officer name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Peer group",
            dataIndex: "peerGroup",
            key: "peerGroup",
        },
        {
            title: "Peer group(lo)",
            dataIndex: "peerGroupLow",
            key: "peerGroupLow",
        },
        {
            title: "Peer group(hi)",
            dataIndex: "peerGroupHigh",
            key: "peerGroupHigh",
        },
        {
            title: "Salary",
            dataIndex: "salary",
            key: "salary",
        },
        {
            title: "Salary(lo)",
            dataIndex: "salaryLow",
            key: "salaryLow",
        },
        {
            title: "Salary(hi)",
            dataIndex: "salaryHigh",
            key: "salaryHigh",
        },
        {
            title: "LTI Target",
            dataIndex: "dollar",
            key: "dollar",
        },
        {
            title: "LTI Target(lo)",
            dataIndex: "dollarLow",
            key: "dollarLow",
        },
        {
            title: "LTI Target(hi)",
            dataIndex: "dollarHigh",
            key: "dollarHigh",
        },
        {
            title: "Payout threshold",
            dataIndex: "payoutThreshold",
            key: "payoutThreshold",
        },
        {
            title: "Payout maximum",
            dataIndex: "payoutMaximum",
            key: "payoutMaximum",
        },
        {title: "Type", dataIndex: "type", key: "type"},
    ]

    return (<Table className="clic" dataSource={datasource} columns={columns} pagination={false} />)

}



const LtiPrinciple = ({data}:{data: LTIPrinciple[]}) => {
    const context = useContext(appContext)
    const { setFocus, setHover } = context

    const datasource = data.map((d,idx) => ({
        key: idx,
        ...d,
        applicableTo: {
            value: d.applicableTo && Object.keys(d.applicableTo).length > 0
                ? d.applicableTo.officerName
                    ? d.applicableTo.officerName
                    : d.applicableTo.role
                : "",
        },
        peerGroupTarget: {
            value: d.peerGroupTarget
                ? typeof (d.peerGroupTarget?.value) == "string" || typeof (d.peerGroupTarget?.value) === "number"
                    ? d.peerGroupTarget?.value
                    : `${d.peerGroupTarget.value.from} - ${d.peerGroupTarget.value.to} ${d.peerGroupTarget.value.kind === "percentile" ? "percentile" : "percentage of median"}`
                : "",
            location: (d.peerGroupTarget as Record<string, any>)?.location
        },
        
    }))
    const render = (value: Located) => RenderLocated({value, setFocus, setHover})

    const columns = ["applicableTo", "peerGroupTarget"].map(title => ({
        title,
        dataIndex: title,
        key: title,
        render,
    }))

    return (
    <Table 
        dataSource={datasource} 
        columns={columns}  
        pagination={false}      
    />)
}


const CompensationPayoutsTable = ({data}: {data: LTIPayouts[]}) => {
    const context = useContext(appContext)
    const { setFocus, setHover } = context
    const render = (value: Located) => <RenderLocated 
        value={value} 
        setFocus={setFocus} 
        setHover={setHover} />

    const datasource = data.map((d,idx) => ({     
        ...d,
        applicableTo: {
            value: d.applicableTo && Object.keys(d.applicableTo).length > 0
                ? d.applicableTo.officerName
                    ? d.applicableTo.officerName
                    : d.applicableTo.role
                : "",
        },

        kind: {
            value: d.rule.incentivePlanComponent || "",
            referenceText: d.rule.referenceText || "",
            location: (d as Record<string, any>)?.location
        },
        threshold: {
            value: d.rule.threshold || "",
            referenceText: d.rule.referenceText || "",
            location: (d.rule as Record<string, any>)?.location
        },
        maximum: {
            value: d.rule.maximum || "",
            referenceText: d.rule.referenceText || "",
            location: (d.rule as Record<string, any>)?.location
        },
        key: idx
    }))

    const columns = ["applicableTo", "kind", "threshold", "maximum"].map(title => ({
        title,
        dataIndex: title,
        key: title,
        render,
    }))

    return <Table dataSource={datasource} columns={columns} pagination={false} />
}

const CompensationTargetTable = ({data}: {data: LTITargets[]}) => {
    const context = useContext(appContext)
    const { setFocus, setHover } = context
    const render = (value: Located) => <RenderLocated 
        value={value} 
        setFocus={setFocus} 
        setHover={setHover} />

    const datasource = data.map((d,idx) => ({    
        ...d,
        applicableTo: {
            value: renderApplicableTo(d.applicableTo)
        },            
        value: {
            value :(typeof d.compensationTarget.value === "object" ? `${d.compensationTarget.value.from} - ${d.compensationTarget.value.to}` : d.compensationTarget.value) || "",
            referenceText: d.compensationTarget.referenceText || "",
            location: (d.compensationTarget as Record<string, any>)?.location
        },
        kind: {
            value: d.compensationTarget.incentivePlanComponent || "",
            referenceText: d.compensationTarget.referenceText || "",
            location: (d.compensationTarget as Record<string, any>)?.location
        },
        unit: {
            value: d.compensationTarget.unit || "",
            referenceText: d.compensationTarget.referenceText || "",
            location: (d.compensationTarget as Record<string, any>)?.location
        }
    }))

    const columns = ["applicableTo", "kind", "value", "unit"].map(title => ({
        title,
        dataIndex: title,
        key: title,
        render,
    }))

    return <Table dataSource={datasource} columns={columns} pagination={false} />
}

export const LtiUseCase = {
    DataView: LtiResults,
    figureLocations
}