// TODO - this is a duplicate of datapointMinimap in chester
// expose it e.g. via shared package
// doc - top section - subsection

import { ConfigProvider, Tree } from "antd"



// a tree structure that does two things
// - 1. defines the nav structure when browsing the reviewer
// - 2. specifies _which datapoints_ are showing in each leaf node of that nav.

// !!WARNING!! 2. couples this nav structure strongly to the namespaces defined in chester/src/domains/chester/types
// because it uses the path through the tree (def14a.cda.lti.targets) to filter the datapoints (which each have a namespace)
// if needed we can decouple this by adding a 'key' field to each leaf node that would specify the namespace (instead of using the tree path), and modifying a few functions in this file.

// each item can have a label field
const datapointMinimap = {
    def14a: {
        background: {
            fiscalYear: {
                label: "Fiscal Year"
            },
            neos: {
                label: "Named Executive Officers"
            }
        },
        cda: {
            label: "C,D & A",
            salary: {
                label: "Salary"
            },
            sti: {
                label: "Short Term Incentives",
                principle: {
                    label: "Peer Group Benchmarks"
                },
                targets: {
                    label: "Compensation Targets"
                }
            },
            lti: {
                label: "Long Term Incentives",
                principle: {
                    label: "Peer Group Benchmarks"
                },
                targets: {
                    label: "Compensation Targets"
                },
                payouts: {
                    label: "Payouts"
                }
            },
            totalCash: {
                label: "Total Cash"
            },
            totalDirectCompensation: {
                label: "Total Direct Compensation"
            },
            totalRewards: {
                label: "Total Rewards"
            },
            sog: {
                label: "Stock Ownership Guidelines",
                target: {
                    label: "Target"
                },
                timeFrame: {
                    label: "Time Frame"
                },
                qualifying: {
                    label: "Qualifying Stocks",
                    description: "Stocks that count towards the stock ownership requirements"
                },
                withHoldingRules: {
                    label: "Withholding Rules"
                }
            },
            clawback: {
                label: "Clawback Policy"
            },
            consultants: {
                label: "Compensation Consultants"
            },
            notes: {
                label: "Notes"
            }
        },
        sct: {
            label: "Summary Compensation",
            table: {
                label: "Summary Compensation Table"
            },
            otherCompensation: {
                label: "Other Compensation",
                // neip: {
                //     label: "Non-Equity Incentive Plan (NEIP) Compensation"
                // },
                retirement: {
                    label: "Retirement"
                },
                insurance: {
                    label: "Insurance"
                },
                cashAndPlanning: {
                    label: "Cash and Planning"
                },
                travelAndEntertainment: {
                    label: "Travel and Entertainment"
                },
                generalServices: {
                    label: "General Services"
                },
                commingled: {
                    label: "Commingled"
                }
            }
        },
        pba: {
            label: "Plan-Based Awards",
            table: {
                label: "Plan-Based Awards Table"
            },
            grants: {
                label: "Grants",
                neip: {
                    label: "Non-Equity Incentive Plan (NEIP)"
                },
                eip: {
                    label: "Equity Incentive Plan (EIP)"
                },
                aos: {
                    label: "All Other Stock (AOS)"
                },
                aoo: {
                    label: "All Other Options (AOO)"
                }
            },
            // plans: {
            //     neip: {
            //         label: "Non-Equity Incentive Plan (NEIP)"
            //     },
            //     neipPayout: {
            //         label: "NEIP Payouts"
            //     },
            //     eip: {
            //         label: "Equity Incentive Plan (EIP)"
            //     },
            //     aos: {
            //         label: "All Other Stock (AOS)"
            //     },
            //     aoo: {
            //         label: "All Other Options (AOO)"
            //     }
            // },
            metrics: {
                label: "Metrics",
                definitions: {
                    label: "Definitions"
                },
                performances: {
                    label: "NEO Performances"
                }
            },
        }

    }
} as const


const buildTreeNode = (key: string, value: any): any => {
    if (typeof value !== 'object' || value === null) return null;

    const children = Object.entries(value)
        .filter(([k]) => k !== 'label')
        .map(([k, v]) => buildTreeNode(`${key}.${k}`, v))
        .filter(Boolean);

    return {
        title: value.label || key.split('.').pop(),
        key: key,
        ...(children.length > 0 && { children })
    };
};

const minimapTree = Object.entries(datapointMinimap.def14a)
    .filter(([section]) => section !== 'label')
    .map(([section, value]) =>
        buildTreeNode(`def14a.${section}`, value)
    );

const findNode = (key: string, tree: { key: string, children?: { key: string }[] }[] = minimapTree): { key: string, children?: { key: string }[] } | undefined => {
    if (!tree) return undefined
    const atLevel = tree.find(node => node.key === key)
    if (atLevel) return atLevel
    for (const node of tree) {
        for (const child of node.children || []) {
            const found = findNode(key, [child])
            if (found) return found
        }
    }
    return undefined
}
const keyToNamespace = (key: string) => {
    const node = findNode(key)
    if (!node) return undefined
    let current = node
    while (current.children) {
        current = current.children[0]
    }
    return current.key
}

export const defaultNamespace = () => keyToNamespace(minimapTree[0].key)

//minimap will always land you on a leaf
export const Minimap = (props: { onSelect: (namespace: string) => void, namespace: string }) => {
    const theme = { components: { Tree: { nodeSelectedBg: "#00c8ff1f" } } }
    return (
        <ConfigProvider theme={theme}>
            <Tree style={{ padding: 6 }}
                treeData={minimapTree}
                selectedKeys={[props.namespace]}
                defaultExpandAll={true}
                onClick={(_, info) => props.onSelect(keyToNamespace(info.key) || info.key)}
            />
        </ConfigProvider>
    )
}


export const breadCrumb = (namespace: string) => {
    if (namespace === "") return []
    const parts = namespace.split(".")
    const ret = []
    let current = datapointMinimap as any
    let idx = 0
    for (const part of parts) {
        idx++
        current = current[part]

        ret.push({
            title: current.label ?? part,
            description: current.description,
            part: parts.slice(0, idx).join(".")
        })
    }
    ret.shift()
    return ret
}   
