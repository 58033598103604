import { appContext, Doc } from "./useApp"
import { useContext } from "react"
import { FilingSelect } from "../../components/FilingSelect"
import { useElementSize } from "@custom-react-hooks/use-element-size"
import { FilingItem } from "../chat-app/MenuPanel"
import { Minimap } from "./Minimap"
import { reviewsContext } from "./useReviews"
import { Flex } from "antd"
import * as ScrollArea from '@radix-ui/react-scroll-area';

export function NavPanel() {
    const context = useContext(appContext)
    const doc = context.doc
    return (
        <>
            <div style={{ marginBottom: 20 }}>
                <FilingSelect onOpen={context.open} />
            </div>
            <div style={{ marginBottom: 20 }}>
                {doc
                    ? <WithDoc doc={doc} close={context.close} />
                    : null
                }
            </div>
        </>
    )
}

const WithDoc = (props: { doc: Doc, close: () => void }) => {
    const { doc, close } = props
    const reviewContext = useContext(reviewsContext)

    const [ , size] = useElementSize<HTMLDivElement>();
    return (<Flex vertical gap={12} >
        <FilingItem
            isSelected={true}
            isFirst={true}
            onClick={() => { }}
            close={close}
            status="loaded"
            width={size.width - 5}
            {...doc}
        />
        <ScrollArea.Root style={{ height: 'calc(100vh - 200px)' }}>
            <ScrollArea.Viewport style={{ height: '100%' }}>
                <Minimap onSelect={(namespace:string) => reviewContext.setNavLocator(namespace)} namespace={reviewContext.navLocator} />
            </ScrollArea.Viewport>
            <ScrollArea.Scrollbar orientation="vertical" className="ScrollAreaScrollbar">
                <ScrollArea.Thumb className="ScrollAreaThumb" />
            </ScrollArea.Scrollbar>
        </ScrollArea.Root>
    </Flex>)
}
