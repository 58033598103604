import { useContext } from "react"
import { Consultant } from "@chester/shared/def14a/cda/consultant"
import { appContext } from "../useApp"
import { Table } from "antd"
import { Located } from "./types";
import { RenderLocated } from "./common";


const figureLocations = (data: Consultant[]) => {
    if (!data) return []
    return data.map(d => (d as Record<string, any>).location)
}

const ConsultantsResults = ({ data }: { data: Consultant[] }) => {
    const context = useContext(appContext)
    const { setFocus, setHover } = context

    const datasource = data.map((d) => ({
        Name: {value: d.name},
        Engagement: {value: d.engagement},
        Fee: {value: d.fee},
        FiscalYear: {value: d.fiscalYear},
    }))

    const render = (value: Located) => RenderLocated({value, setFocus, setHover})

    const columns = ["Name", "Engagement", "Fee", "FiscalYear", "Period"].map(title => ({
        title,
        dataIndex: title,
        key: title,
        render,
    }))

    return (<Table dataSource={datasource} columns={columns} />)
}

export const ConsultantsUseCase = {
    DataView: ConsultantsResults,
    figureLocations
}