import { useCallback, useMemo, useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  processCharterDocuments,
  pollCharterJobStatus,
  getCharterJobResults,
  ProcessCharterDocumentsStatus,
  CharterJobResults,
  CharterType,
} from "../../api-clients/charlie";

const JOB_STATUSES = [
  "pending",
  "in_progress",
  "ready",
  "error",
  "partial_failure",
] as const;

// Hook to submit a new charter job
export function useSubmitCharterDocumentsJob({
  charterType,
  tickers,
}: {
  charterType: CharterType;
  tickers: string[];
}) {
  const [jobId, setJobId] = useState<string | undefined>();

  const requestId = useMemo(() => `request-${Date.now()}`, []); // Ensure unique requestId

  const mutation = useMutation({
    mutationFn: async () => {
      const response = await processCharterDocuments({
        requestId,
        tickers,
        charterType,
      });
      setJobId(response.jobId);
      return response;
    },
    retry: 1, // Avoid unnecessary retries
  });

  return { jobId, submit: mutation.mutate, isSubmitting: mutation.isPending };
}

// Hook to poll the job status
export function usePollCharterJobStatus(jobId: string) {
  const fetchJobStatus = useCallback(
    () => pollCharterJobStatus(jobId),
    [jobId]
  );

  const { data, isLoading, isError, refetch } =
    useQuery<ProcessCharterDocumentsStatus>({
      queryKey: ["charterJobStatus", jobId],
      queryFn: fetchJobStatus,
      refetchInterval: (data) => {
        const status = data.state.data?.status;
        if (!status) return false;
        const shouldStopPolling =
          !JOB_STATUSES.includes(status) ||
          status === "ready" ||
          status === "error" ||
          status === "partial_failure";
        return shouldStopPolling ? false : 3000;
      },
      enabled: !!jobId, // Ensure query is only enabled when jobId exists
    });

  // Check if there is at least one partial result for the job
  const hasPartialResults = useMemo(() => {
    return Object.entries(data?.tickerStatuses || {}).some(
      ([, status]) => status.status === "ready"
    );
  }, [data]);

  // Tickers that are ready to show in the UI
  const processedTickers = useMemo(() => {
    return Object.entries(data?.tickerStatuses || {})
      .filter(([, status]) => status.status === "ready")
      .map(([ticker]) => ticker);
  }, [data]);

  return {
    jobStatus: data,
    isLoading,
    isError,
    refetch,
    isCompleted: data?.status === "ready",
    hasPartialFailure: data?.status === "partial_failure",
    hasPartialResults,
    processedTickers,
  };
}

// Hook to fetch job results
export function useCharterJobResults({
  jobId,
  processedTickers,
}: {
  jobId: string;
  processedTickers: string[];
}) {
  const fetchResults = useCallback(() => getCharterJobResults(jobId), [jobId]);

  const tickersKey = useMemo(() => processedTickers.join(","), [processedTickers]);

  const { data, isLoading, isError, refetch, isRefetching } =
    useQuery<CharterJobResults>({
      queryKey: ["charterJobResults", jobId, tickersKey],
      queryFn: fetchResults,
      enabled: !!jobId,
    });

  return { results: data, isLoading, isError, refetch, isRefetching };
}
