import { Page } from "../../components/Page";
import { appContext, useApp } from "./useApp";
import { Flex, theme } from "antd";
import { FilingSelect } from "../../components/FilingSelect";
import { useContext } from "react";
import { ReviewerScreen } from "./screens/view";
import { loggerContext, useLogger } from "./useLogger";
import { usePostHog } from "src/hooks/usePostHog";

const POSTHOG_KEY = "phc_rHnGzEdX4Mj6wmNC2rosFeWD9rHejBV6oZsugWvduvB"

export function ReviewerPage() {
  // useClarity(REVIEWER_CLARITY_KEY)
  usePostHog(POSTHOG_KEY)
  const logger = useLogger()
  
  return (
    <Page>
      <loggerContext.Provider value={logger}>
        <Reviewer />
      </loggerContext.Provider>
    </Page>
  );
}

const Reviewer = ()=> {
  const appContextValue = useApp()
  const hasDoc = appContextValue.doc!!
  return (
          <appContext.Provider value={appContextValue}>
            {hasDoc ? <ReviewerScreen /> : <StartScreen />}
          </appContext.Provider>
  );
}

function StartScreen() {
  const { token } = theme.useToken();
  const context = useContext(appContext);
  return (
    <Flex justify="center">
      <div
        style={{
          marginTop: 100,
          borderRadius: 15,
          padding: 15,
          paddingBottom: 25,
          backgroundColor: token.colorBgContainer,
          boxShadow: token.boxShadowTertiary,
        }}
      >
        <div style={{ marginBottom: 4, width: 420 }}>
          Start by selecting a document
        </div>
        <FilingSelect onOpen={context.open} />
      </div>
    </Flex>
  );
}
