import { useState, useMemo } from "react";
import { AutoComplete, Input, theme } from "antd";
import { useCiksQuery } from "../hooks/useCiks";

export function TickersSelect(props: {
  disabled?: boolean;
  onSelectionChange(selectedTickers: string[]): void;
}) {
  const [filter, setFilter] = useState("");
  const [selectedTickers, setSelectedTickers] = useState<string[]>([]);
  const ciksQuery = useCiksQuery();
  const { token } = theme.useToken();

  const tickerOptions = useMemo(() => {
    const words = filter.toLowerCase().trim().split(" ").filter(Boolean);
    const options = (ciksQuery.data || []).map(({ ticker, title }) => {
      const value = `${ticker} ${title}`.toLowerCase();
      const matchesFilter =
        words.length === 0 || words.every((word) => value.includes(word));
      return matchesFilter
        ? {
            value: ticker,
            label: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: 13,
                  color: token.colorText,
                }}
              >
                <span>{ticker}</span>
                <span style={{ color: token.colorTextSecondary }}>{title}</span>
              </div>
            ),
          }
        : null;
    });

    return options.filter(Boolean) as {
      value: string;
      label: JSX.Element;
    }[];
  }, [filter, ciksQuery.data, token.colorText, token.colorTextSecondary]);

  const handleSelection = (value: string) => {
    if (!selectedTickers.includes(value)) {
      const updatedTickers = [...selectedTickers, value];
      setSelectedTickers(updatedTickers);
      props.onSelectionChange(updatedTickers);
    }
    setFilter(""); // Clear the input after selection
  };

  const handleRemove = (ticker: string) => {
    const updatedTickers = selectedTickers.filter((t) => t !== ticker);
    setSelectedTickers(updatedTickers);
    props.onSelectionChange(updatedTickers);
  };

  return (
    <div>
      <AutoComplete
        disabled={props.disabled}
        placeholder="Enter tickers"
        allowClear
        onSearch={setFilter}
        onSelect={handleSelection}
        value={filter}
        style={{ width: "100%" }}
        options={tickerOptions}
      >
        <Input.Search disabled={props.disabled} size="large" />
      </AutoComplete>
      <div style={{ marginTop: 10, display: "flex", flexWrap: "wrap", gap: 5 }}>
        {selectedTickers.map((ticker) => (
          <div
            key={ticker}
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: `${token.colorPrimary}33`,
              padding: "5px 10px",
              borderRadius: 4,
            }}
          >
            <span>{ticker}</span>
            <span
              style={{
                marginLeft: 5,
                cursor: "pointer",
                color: token.colorError,
              }}
              onClick={() => handleRemove(ticker)}
            >
              ✕
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
