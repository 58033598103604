import { Flex } from "antd"
import { FormItem, Located } from "../../DynamicForm"



// note: this should go in SCT breakout - ie this is an unregistered draft
export const Neip = () => {
    return (
        <Located path="" >
            <Flex gap={4} className="table-style">
                <FormItem path="neipComp" />
                <FormItem path="ltNeipComp" />
                <FormItem path="otherNeipComp" />
                <FormItem path="actualLongTermNeipPayouts" />
            </Flex>
        </Located>
    )
}


export const Retirement = () => {
    return (

        <Flex gap={4} className="table-style">
            {/* <FormItem path="totalRetirementBenefit" label="Total Retirement Benefit" /> */}
            <Located path="definedContrib1" noEdit>
                <FormItem path="definedContrib1.value" label="Defined Contrib 1" />
            </Located>
            <Located path="definedContrib2" noEdit>
                <FormItem path="definedContrib2.value" label="Defined Contrib 2" />
            </Located>
            <Located path="esopContrib" noEdit>
                <FormItem path="esopContrib.value" label="ESOP or DC3" />
            </Located>
            <Located path="definedBenefitContrib" noEdit>
                <FormItem path="definedBenefitContrib.value" label="Defined Benefit Accrual" />
            </Located>
            {/* <Located path="retBenefitPayments" noEdit>
                <FormItem path="retBenefitPayments.value" label="Ret Benefit Payments" />
            </Located> */}
        </Flex>
    )
}
export const Insurance = () => {
    return (
        <Flex gap={4} className="table-style">
            {/* <FormItem path="totalExecutiveInsuranceBenefits" label="Total Executive Insurance Benefits" /> */}
            <Located path="lifeInsurance1" noEdit>
                <FormItem path="lifeInsurance1.value" label="Life Insurance 1" />
            </Located>
            <Located path="lifeInsurance2" noEdit>
                <FormItem path="lifeInsurance2.value" label="Life Insurance 2" />
            </Located>
            <Located path="adAndD" noEdit>
                <FormItem path="adAndD.value" label="AD&D Insurance" />
            </Located>
            <Located path="disability" noEdit>
                <FormItem path="disability.value" label="Disability" />
            </Located>
            <Located path="longTermCare" noEdit>
                <FormItem path="longTermCare.value" label="Long Term Care" />
            </Located>
            <Located path="liability" noEdit>
                <FormItem path="liability.value" label="Liability" />
            </Located>
            <Located path="medDentalVision" noEdit>
                <FormItem path="medDentalVision.value" label="Med/Dental/Vision" />
            </Located>
            <Located path="postRetirementSupplemental" noEdit>
                <FormItem path="postRetirementSupplemental.value" label="Post-Retirement Supplemental" />
            </Located>
            <Located path="physicals" noEdit>
                <FormItem path="physicals.value" label="ExecutivePhysical" />
            </Located>
            <Located path="businessTravelAccident" noEdit>
                <FormItem path="businessTravelAccident.value" label="Bus. Travel Accident Ins." />
            </Located>
            <Located path="other" noEdit>
                <FormItem path="other.value" label="Other Insurance" />
            </Located>
            <Located path="insurancePremium" noEdit>
                <FormItem path="insurancePremium.value" label="Insurance Premium" />
            </Located>
            <Located path="commingledValue" noEdit>
                <FormItem path="commingledValue.value" label="Commingled Value" />
            </Located>
        </Flex>
    )
}

export const CashAndPlanning = () => {
    return (
        <Flex gap={4} className="table-style">
            {/* <FormItem path="totalCashAndPlanningBenefits" label="Total Cash and Planning Benefits" /> */}
            <Located path="financialPlanning" noEdit>
                <FormItem path="financialPlanning.value" label="Financial Planning" />
            </Located>
            <Located path="estatePlanning" noEdit>
                <FormItem path="estatePlanning.value" label="Estate Planning" />
            </Located>
            <Located path="legalAssistance" noEdit>
                <FormItem path="legalAssistance.value" label="Legal Assistance" />
            </Located>
            <Located path="taxGrossUp" noEdit>
                <FormItem path="taxGrossUp.value" label="Tax Gross-Up / Equalization" />
            </Located>
            <Located path="spendingAllowance" noEdit>
                <FormItem path="spendingAllowance.value" label="Spending Allowance" />
            </Located>
            <Located path="nqdcInterestAppreciation" noEdit>
                <FormItem path="nqdcInterestAppreciation.value" label="NQDC Interest / Apprec" />
            </Located>
            <Located path="rsRsuDividend" noEdit>
                <FormItem path="rsRsuDividend.value" label="RSU/RSU Dividend" />
            </Located>
            <Located path="outplacementServices" noEdit>
                <FormItem path="outplacementServices.value" label="Outplacement Services" />
            </Located>
            <Located path="severence" noEdit>
                <FormItem path="severence.value" label="Severance" />
            </Located>
            <Located path="consultingFees" noEdit>
                <FormItem path="consultingFees.value" label="Consulting Fees" />
            </Located>
            <Located path="adminExpenses" noEdit>
                <FormItem path="adminExpenses.value" label="Admin Expenses" />
            </Located>
            <Located path="wfhAllowance" noEdit>
                <FormItem path="wfhAllowance.value" label="WFH Allowance" />
            </Located>
            <Located path="bonusCommission" noEdit>
                <FormItem path="bonusCommission.value" label="Bonus Commission" />
            </Located>
            <Located path="otherCashPayments" noEdit>
                <FormItem path="otherCashPayments.value" label="Other Cash Payments" />
            </Located>
        </Flex>
    )
}

export const TravelAndEntertainment = () => {
    return (
        <Flex gap={4} className="table-style">

            {/* <FormItem path="totalTravelAndEntertainmentBenefits" label="Total Travel and Entertainment Benefits" /> */}
            <Located path="commutingExpense" noEdit>
                <FormItem path="commutingExpense.value" label="Commuting Expense" />
            </Located>
            <Located path="autoAutoInsurance" noEdit>
                <FormItem path="autoAutoInsurance.value" label="Auto/Auto Insurance" />
            </Located>
            <Located path="clubAssnDues" noEdit>
                <FormItem path="clubAssnDues.value" label="Club/Assn Dues" />
            </Located>
            <Located path="seasonTickets" noEdit>
                <FormItem path="seasonTickets.value" label="Season Tickets" />
            </Located>
            <Located path="housingSemiPermanent" noEdit>
                <FormItem path="housingSemiPermanent.value" label="Housing (Semi-Permanent)" />
            </Located>
            <Located path="businessPersonalTravel" noEdit>
                <FormItem path="businessPersonalTravel.value" label="Business / Personal Travel" />
            </Located>
            <Located path="assignmentRelocationExpenses" noEdit>
                <FormItem path="assignmentRelocationExpenses.value" label="Assignment / Relocation Expenses" />
            </Located>
            <Located path="otherTravelEntertainment" noEdit>
                <FormItem path="otherTravelEntertainment.value" label="Other Travel / Entertainment" />
            </Located>
        </Flex>
    )
}

export const GeneralServices = () => {
    return (
        <Flex gap={4} className="table-style">
            {/* <FormItem path="totalGeneralServicesBenefits" label="Total General Services Benefits" /> */}
            <Located path="personalSecurity" noEdit>
                <FormItem path="personalSecurity.value" label="Personal Security" />
            </Located>
            <Located path="charitableAwards" noEdit>
                <FormItem path="charitableAwards.value" label="Charitable Awards" />
            </Located>
            <Located path="companyProducts" noEdit>
                <FormItem path="companyProducts.value" label="Company Products" />
            </Located>
            <Located path="giftsAwards" noEdit>
                <FormItem path="giftsAwards.value" label="Gifts / Awards" />
            </Located>
            <Located path="educationAssistance" noEdit>
                <FormItem path="educationAssistance.value" label="Education Assistance" />
            </Located>
            <Located path="cellInternet" noEdit>
                <FormItem path="cellInternet.value" label="Cell / Internet" />
            </Located>
            <Located path="otherGeneralServices" noEdit>
                <FormItem path="otherGeneralServices.value" label="Other General Services" />
            </Located>
        </Flex>
    )
}

export const Commingled = () => {
    return (
        <Flex gap={4} className="table-style">
            <Located path="commingled1" noEdit>
                <FormItem path="commingled1.value" label="Commingled 1" />
            </Located>
            <Located path="commingled2" noEdit>
                <FormItem path="commingled2.value" label="Commingled 2" />
            </Located>
            <Located path="unspecifiedPerq" noEdit>
                <FormItem path="unspecifiedPerq.value" label="Unspecified Perq" />
            </Located>
            <Located path="unspecifiedComponents" noEdit>
                <FormItem path="unspecifiedComponents.value" label="Unspecified Components" />
            </Located>
        </Flex>
    )
}