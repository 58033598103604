import { Flex } from "antd"
import { FormItem } from "../../DynamicForm"

import { Located } from "../../DynamicForm"

export const NeipGrant = () => {
    return (
        <Located path="">
            <Flex gap={4} className="table-style">
                <FormItem path="grantDate" label="Grant Date" date={true}  />
                <FormItem path="threshold" label="Threshold" />
                <FormItem path="target" label="Target" />
                <FormItem path="maximum" label="Maximum" />
                <FormItem path="grantDateFairValue" label="Grant Date Fair Value" />

                <FormItem path="purpose" />
                
                <FormItem path="vestingSchedule" />
                
                <FormItem path="performancePeriod" />
                <FormItem path="performanceCharacteristic" />
                <FormItem path="payoutType" />

                
            </Flex>
        </Located>
    )
}

export const EipGrant = () => {
    return (
        <Located path="">
            <Flex gap={4} className="table-style">
                <FormItem path="grantDate" label="Grant Date" date={true}  />
                <FormItem path="threshold" label="Threshold" />
                <FormItem path="target" label="Target" />
                <FormItem path="maximum" label="Maximum" />
                <FormItem path="grantDateFairValue" label="Grant Date Fair Value" />

                <FormItem path="purpose" />
                <FormItem path="stockPrice" />
                <FormItem path="awardType" />
                <FormItem path="vestingSchedule" />
                <FormItem path="postVestingHoldingRequirement" />
                <FormItem path="holdingPeriod" />
                <FormItem path="performancePeriod" />
                <FormItem path="performanceCharacteristic" />
                <FormItem path="payoutType" />

                
            </Flex>
        </Located>
    )
}