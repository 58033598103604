import { get, post } from "./lib";

export type CharterType = "compensation_committee" | "audit_committee" | "nominating_committee" | "board_governance" | "corporate_bylaws";

export const CHARTER_TYPES: { value: CharterType; label: string }[] = [
  { value: "compensation_committee", label: "Compensation Committee Charter" },
  { value: "audit_committee", label: "Audit Committee Charter" },
  {
    value: "nominating_committee",
    label: "Nominating & Governance Committee Charter",
  },
  { value: "board_governance", label: "Board Governance Guidelines" },
  { value: "corporate_bylaws", label: "Corporate Bylaws" },
];

export type CharterJobResultsForTicker = {
  ticker: string;
  charterType: CharterType;
  charter?: {
    company_name: string;
    ticker: string;
    industry: string;
    sources: string[];
    committee_name: string;
    committee_name_as_per_company: string;
    charter_availability: string;
    charter_reviewed_date: string;
    charter_reviewed_date_disclosed: string;
  };
  extractResponse?: {
    results: [
      {
        company_name?: string; // For validation
        charter_date?: string; // To check document recency
        charter_document_url: string; // The most critical field
        source_page_url?: string; // The webpage FireCrawl got the charter from
        extracted_text_snippet?: string;
      }
    ];
  };
  searchResponse?: {
    answer?: string;
    query: string;
    results: {
      title: string;
      url: string;
      content: string;
      rawContent?: string;
      score: number;
      publishedDate: string;
    }[];
  };
};

export type CharterJobResults = CharterJobResultsForTicker[];

export type ProcessCharterDocumentsStatus = {
  status: "pending" | "in_progress" | "ready" | "error" | "partial_failure";
  progress: number;
  payload: {
    requestId: string;
    tickers: string[];
    charterType: CharterType;
  };
  readyAt?: Date;
  tickerStatuses: ProcessCharterTickerStatuses;
};

export type ProcessCharterTickerStatuses = Record<
  string,
  {
    ticker: string;
    status: "pending" | "in_progress" | "ready" | "error";
    progress: number;
    info?: {
      step: string;
      error?: string;
    };
  }
>;

/**
 * Submits a batch job for processing charter documents.
 * @param requestId Unique identifier for the request
 * @param tickers Array of tickers to process
 * @param charterType The type of charter to search for
 * @returns Promise resolving to the submitted job ID
 */
export function processCharterDocuments({
  requestId,
  tickers,
  charterType,
}: {
  requestId: string;
  tickers: string[];
  charterType: CharterType;
}): Promise<{ jobId: string }> {
  return post(`/api/charlie/processBatch.json`, { requestId, tickers, charterType });
}

/**
 * Polls the status of a charter processing job.
 * @param jobId The job ID to query
 * @returns Promise resolving to the job status
 */
export async function pollCharterJobStatus(
  jobId: string
): Promise<ProcessCharterDocumentsStatus> {
  return get(`/api/charlie/status.json`, { jobId });
}

/**
 * Retrieves the results of a completed charter processing job.
 * @param jobId The job ID to fetch results for
 * @returns Promise resolving to an array of charter documents
 */
export async function getCharterJobResults(
  jobId: string
): Promise<CharterJobResults> {
  return get(`/api/charlie/results.json`, { jobId });
}
