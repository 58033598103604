import { useEffect, useState } from "react";

export interface HighlightEventDetail {
  start: number | string;
  end: number | string;
}

export function triggerHighlightEvent(detail: HighlightEventDetail) {
  const event = new CustomEvent<HighlightEventDetail>("highlight", {
    detail,
  });
  document.dispatchEvent(event);
}

export function useHighlightEvent() {
  const [detail, setDetail] = useState<HighlightEventDetail | undefined>();

  useEffect(() => {
    const handleHighlightEvent = (event: CustomEvent<HighlightEventDetail>) => {
      setDetail(event.detail);
    };

    document.addEventListener(
      "highlight",
      handleHighlightEvent as EventListener
    );

    return () => {
      document.removeEventListener(
        "highlight",
        handleHighlightEvent as EventListener
      );
    };
  }, []);
  return { detail };
}
