import { Page } from "../../components/Page";
import { CharlieProvider, useCharlie } from "./CharlieContext";
import { CharlieReadyScreen } from "./screens/ready";
import { CharlieStartScreen } from "./screens/start";
import { usePostHog } from "src/hooks/usePostHog";

export function CharliePage() {
  const POSTHOG_KEY = "phc_rHnGzEdX4Mj6wmNC2rosFeWD9rHejBV6oZsugWvduvB"
  usePostHog(POSTHOG_KEY)
  
  return (
    <Page>
      <CharlieProvider>
        <CharlieAppContent />
      </CharlieProvider>
    </Page>
  );
}

function CharlieAppContent() {
  const { tickers } = useCharlie();

  return tickers.length === 0 ? <CharlieStartScreen /> : <CharlieReadyScreen />;
}
