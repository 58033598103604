function createWordVector(text: string): Map<string, number> {
    const words = text.toLowerCase().split(/\W+/);
    const vector = new Map<string, number>();

    words.forEach(word => {
        if (word) {
            vector.set(word, (vector.get(word) || 0) + 1);
        }
    });

    return vector;
}

function calculateCosineSimilarity(text1: string, text2: string): number {
    const vector1 = createWordVector(text1);
    const vector2 = createWordVector(text2);

    let dotProduct = 0;
    let magnitude1 = 0;
    let magnitude2 = 0;

    // Calculate dot product and magnitudes
    vector1.forEach((count1, word) => {
        const count2 = vector2.get(word) || 0;
        dotProduct += count1 * count2;
        magnitude1 += count1 * count1;
    });

    vector2.forEach((count2) => {
        magnitude2 += count2 * count2;
    });

    // Calculate cosine similarity
    const similarity = dotProduct / (Math.sqrt(magnitude1) * Math.sqrt(magnitude2));
    return similarity || 0;
}

export { calculateCosineSimilarity };

