import { FormItem, Located } from "../../DynamicForm"

export const Notes = () => {
    return (
        <>
        <Located path="">
            <FormItem path="excerpt" text={true} />
        </Located>
        </>
    )
} 