import { FormItem, Located } from "../../DynamicForm"
import { Flex } from "antd"
export const TotalDirectCompensation = () => {
    return (
        <Located path="">
            <Flex gap={4} className="table-style">
                <FormItem path="kind" />
                <FormItem path="target" />
                <FormItem path="low" />
                <FormItem path="high" />
                <FormItem path="period" />
            </Flex>
        </Located>
    )
}