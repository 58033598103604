import { CSSProperties, PropsWithChildren, useEffect, useState } from "react";
import * as XLSX from "xlsx";
import Split from "react-split";
import {
  Table,
  Button,
  Tabs,
  Progress,
  Badge,
  Skeleton,
  Card,
  List,
} from "antd";
import { useCharlie } from "../CharlieContext";
import {
  useCharterJobResults,
  usePollCharterJobStatus,
  useSubmitCharterDocumentsJob,
} from "../useCharterDocuments";
import { CharterJobResults } from "src/api-clients/charlie";
import { produceWorkbook } from "./spreadsheet";

export function CharlieReadyScreen() {
  const { tickers, charterType } = useCharlie();
  const { jobId, submit, isSubmitting } = useSubmitCharterDocumentsJob({
    charterType,
    tickers,
  });

  useEffect(() => {
    if (!jobId && !isSubmitting) {
      submit();
    }
  }, [jobId, isSubmitting, submit]);

  return (
    <div style={{ height: "100%", borderRadius: "inherit" }}>
      {jobId ? <ReadyScreenContent jobId={jobId} /> : <Skeleton active />}
    </div>
  );
}

function getStatusBadge(status?: string) {
  const statusMap: Record<
    string,
    {
      text: string;
      color: "default" | "processing" | "success" | "error" | "warning";
    }
  > = {
    pending: { text: "Pending", color: "default" },
    in_progress: { text: "In Progress", color: "processing" },
    ready: { text: "Ready", color: "success" },
    error: { text: "Error", color: "error" },
    partial_failure: { text: "Partial Failure", color: "warning" },
  };

  return (
    <Badge
      status={statusMap[status || "unknown"]?.color || "warning"}
      text={statusMap[status || "unknown"]?.text || "Unknown"}
    />
  );
}

const calculateProgress = (progress: number): number => {
  return Math.min(100, Math.max(0, Math.round(progress * 100)));
};

function ReadyScreenContent({ jobId }: { jobId: string }) {
  const [sizes, setSizes] = useState([30, 70]);
  const { jobStatus, isLoading, isError, hasPartialResults, processedTickers } =
    usePollCharterJobStatus(jobId);

  return (
    <Split
      sizes={sizes}
      minSize={200}
      expandToMin
      gutterSize={8}
      snapOffset={5}
      dragInterval={1}
      direction="horizontal"
      cursor="col-resize"
      onDragEnd={setSizes}
      className="split"
    >
      {/* Left Panel: Global Status + Ticker Statuses */}
      <SplitPanel>
        <div
          style={{
            padding: "16px",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* Global Status (Sticky) */}
          <div
            style={{
              top: 0,
              zIndex: 10,
              paddingBottom: "10px",
              borderBottom: "1px solid #ddd",
            }}
          >
            <div style={{ marginBottom: "10px" }}>
              <strong>Status:</strong> {getStatusBadge(jobStatus?.status)}
            </div>
            <Progress
              percent={calculateProgress(jobStatus?.progress || 0)}
              status={
                jobStatus?.status === "error"
                  ? "exception"
                  : jobStatus?.status === "ready"
                  ? "success"
                  : "active"
              }
              strokeColor={
                jobStatus?.status === "partial_failure" ? "#faad14" : undefined
              }
              size="small"
            />
            <h4 style={{ marginTop: "20px" }}>Status by Ticker</h4>
          </div>

          {/* Scrollable List of Tickers */}
          <div
            style={{
              flex: 1,
              overflowY: "auto",
              maxHeight: "100vh",
              paddingRight: "10px",
            }}
          >
            <List
              dataSource={Object.values(jobStatus?.tickerStatuses || {})}
              renderItem={(item) => (
                <Card
                  size="small"
                  title={item.ticker}
                  style={{ marginBottom: "10px" }}
                >
                  <p>
                    <strong>Status:</strong> {getStatusBadge(item.status)}
                  </p>
                  <p>
                    <strong>Progress:</strong>
                    <Progress
                      percent={calculateProgress(item.progress)}
                      status={
                        item?.status === "error"
                          ? "exception"
                          : item?.status === "ready"
                          ? "success"
                          : "active"
                      }
                      size="small"
                    />
                  </p>
                  <p>
                    <strong>Current Step:</strong>{" "}
                    {item.info?.step || "No information available"}
                  </p>
                  {item.info?.error && (
                    <p style={{ color: "red" }}>
                      <strong>Error:</strong> {item.info.error}
                    </p>
                  )}
                </Card>
              )}
            />
          </div>
        </div>
      </SplitPanel>

      {/* Right Panel: Main Job Result */}
      <SplitPanel>
        {isLoading ? (
          <LoadingSkeleton />
        ) : isError ? (
          <div style={{ padding: "20px", color: "red" }}>
            Failed to load job status
          </div>
        ) : hasPartialResults ? (
          <CharterDocument jobId={jobId} processedTickers={processedTickers} />
        ) : (
          <LoadingSkeleton />
        )}
      </SplitPanel>
    </Split>
  );
}

/**
 * Displays a skeleton loader for a loading state
 */
function LoadingSkeleton() {
  return (
    <div style={{ padding: "20px" }}>
      <Skeleton active />
      <br />
      <Skeleton active paragraph={{ rows: 3 }} />
    </div>
  );
}

function SplitPanel({ children }: PropsWithChildren) {
  return (
    <div style={{ height: "100%", width: "100%", overflow: "visible" }}>
      {children}
    </div>
  );
}

const expandedRowStyle: CSSProperties = {
  backgroundColor: "#fafafa",
  borderLeft: "4px solid #1890ff",
  padding: "10px",
};

const downloadResultsAsXlsx =
  ({ jobId, results }: { jobId: string; results: CharterJobResults }) => {
    const workbook = produceWorkbook(results);
    XLSX.writeFile(workbook, `Charter_Results_${jobId}.xlsx`);
  }

export function CharterDocument({
  jobId,
  processedTickers,
}: {
  jobId: string;
  processedTickers: string[];
}) {
  const { results, isLoading, isError, refetch, isRefetching } =
    useCharterJobResults({
      jobId,
      processedTickers,
    });

  if (isRefetching || isLoading || !results) return <Skeleton active />;
  if (isError)
    return <div style={{ color: "red" }}>Failed to load results</div>;
  if (!results?.length) return <div>No results found</div>;

  const columns = [
    {
      title: "Company Name",
      dataIndex: ["charter", "company_name"],
      key: "company_name",
    },
    { title: "Ticker", dataIndex: "ticker", key: "ticker" },
    {
      title: "Sources",
      dataIndex: ["charter", "sources"],
      key: "sources",
      render: (sources: string[]) =>
        sources?.length ? (
          <ul style={{ paddingLeft: "15px" }}>
            {sources.map((url, index) => (
              <li key={index}>
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#1890ff", fontWeight: "bold" }}
                >
                  View Source {index + 1}
                </a>
              </li>
            ))}
          </ul>
        ) : (
          "No Sources"
        ),
    },
    { title: "Industry", dataIndex: ["charter", "industry"], key: "industry" },
    {
      title: "Committee Name",
      dataIndex: ["charter", "committee_name"],
      key: "committee_name",
    },
    {
      title: "Committee Name (As per Company)",
      dataIndex: ["charter", "committee_name_as_per_company"],
      key: "committee_name_as_per_company",
    },
    {
      title: "Charter Availability",
      dataIndex: ["charter", "charter_availability"],
      key: "charter_availability",
    },
    {
      title: "Charter Reviewed Date",
      dataIndex: ["charter", "charter_reviewed_date"],
      key: "charter_reviewed_date",
    },
    {
      title: "Charter Reviewed Date Disclosed",
      dataIndex: ["charter", "charter_reviewed_date_disclosed"],
      key: "charter_reviewed_date_disclosed",
    },
  ];

  const extractColumns = [
    { title: "Charter Date", dataIndex: "charter_date", key: "charter_date" },
    { title: "Company Name", dataIndex: "company_name", key: "company_name" },
    {
      title: "Charter Document URL",
      dataIndex: "charter_document_url",
      key: "charter_document_url",
      render: (url: string) => (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#1890ff", fontWeight: "bold" }}
        >
          View Document
        </a>
      ),
    },
    {
      title: "Source Page URL",
      dataIndex: "source_page_url",
      key: "source_page_url",
      render: (url?: string) =>
        url ? (
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#1890ff" }}
          >
            Source Page
          </a>
        ) : (
          "N/A"
        ),
    },
    {
      title: "Extracted Text Snippet",
      dataIndex: "extracted_text_snippet",
      key: "extracted_text_snippet",
      render: (text?: string) => (text ? text.slice(0, 100) + "..." : "N/A"), // Shortened snippet for display
    },
  ];

  const searchColumns = [
    { title: "Title", dataIndex: "title", key: "title" },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      render: (url: string) => (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#1890ff", fontWeight: "bold" }}
        >
          View Page / Document
        </a>
      ),
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
      render: (content: string) => content.slice(0, 100) + "...", // Shortened content for display
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
    },
  ];

  return (
    <div style={{ padding: "15px" }}>
      <Button
        onClick={() => refetch()}
        type="primary"
        style={{ marginBottom: "10px" }}
        loading={isRefetching}
      >
        Refresh
      </Button>

      <Button
        onClick={()=> downloadResultsAsXlsx({ jobId, results })}
        type="default"
        style={{ marginBottom: "10px", marginLeft: "10px" }}
      >
        Download as XLSX
      </Button>

      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Table View" key="1">
          <Table
            dataSource={results}
            columns={columns}
            rowKey="ticker"
            pagination={{ pageSize: 4 }}
            bordered
            expandable={{
              expandedRowRender: (record) => (
                <div style={expandedRowStyle}>
                  <h4 style={{ marginBottom: "10px", color: "#1890ff" }}>
                    Pages Searched for the Charter Document
                  </h4>
                  <Table
                    dataSource={record.searchResponse?.results}
                    columns={searchColumns}
                    rowKey="charter_document_url"
                    pagination={false}
                    bordered
                  />

                  <br />

                  <h4 style={{ marginBottom: "10px", color: "#1890ff" }}>
                    Pages Found on the Extraction Process
                  </h4>
                  <Table
                    dataSource={record.extractResponse?.results}
                    columns={extractColumns}
                    rowKey="charter_document_url"
                    pagination={false}
                    bordered
                  />
                </div>
              ),
              rowExpandable: (record) =>
                (record.extractResponse?.results?.length || 0) > 0 ||
                (record.searchResponse?.results?.length || 0) > 0,
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Raw JSON View" key="2">
          <pre
            style={{
              background: "#f6f8fa",
              borderRadius: "4px",
              overflowX: "auto",
            }}
          >
            {JSON.stringify(results, null, 2)}
          </pre>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}
