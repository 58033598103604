import * as ScrollArea from "@radix-ui/react-scroll-area";
import { useContext, useState } from "react";
import { FileTextOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Input, theme } from "antd";
import { MarkdownResponse, ResponseMessage, UserMessage } from "./Messages";
import { chatContext } from "./useChatApp";

export function ChatPanel() {
  const { token } = theme.useToken();
  const context = useContext(chatContext);
  if (!context.current.doc) throw new Error("Expected Doc");

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: `${token.colorBgLayout}`,
        borderRadius: 15,
        boxShadow: token.boxShadowTertiary,
        border: `solid 1px ${token.colorBgContainer}`,
      }}
    >
      <div
        style={{
          minHeight: 0,
          height: "100%",
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <div
          style={{
            padding: "5px 5px 2px 10px",
            borderRadius: "13px 13px 0 0",
            backgroundColor: token.colorBgContainer,
            backgroundImage: `linear-gradient(to top, ${token.colorBgLayout} 30%, ${token.colorBgLayout}66 100% )`,
          }}
        >
          <div
            style={{
              display: "flex",
              gap: 5,
              borderRadius: 10,
              padding: "5px 10px",
              marginRight: 5,
              fontSize: 13,
              alignItems: "center",
            }}
          >
            <FileTextOutlined />
            <strong>{context.current.doc?.ticker}</strong>
            <span>{"/"}</span>
            <strong>{context.current.doc?.form}</strong>
            <span>{"/"}</span>
            <strong>{context.current.doc?.year}</strong>
          </div>
        </div>
        <div
          style={{
            maxHeight: "100%",
            minHeight: 0,
            height: "100%",
            width: "100%",
            borderRadius: "15px 15px 0 0",
            overflow: "hidden",
            padding: "7px 10px 0",
            backgroundColor: token.colorBgContainer,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <div
            style={{
              maxHeight: "100%",
              borderRadius: "10px 10px 0 0",
              overflow: "hidden",
            }}
          >
            <ChatMessages />
          </div>
        </div>
        <div
          style={{
            flex: 0,
            padding: 10,
            paddingTop: 5,
            backgroundColor: token.colorBgContainer,
            borderRadius: "0 0 15px 15px",
          }}
        >
          <UserInputForm />
        </div>
      </div>
    </div>
  );
}

function ChatMessages() {
  const context = useContext(chatContext);
  if (!context.current.doc) throw new Error("Expected Doc");

  return (
    <ScrollArea.Root className="ScrollAreaRoot">
      <ScrollArea.Viewport className="ScrollAreaViewport">
        {context.current.messages.map((msg, i) => (
          <div key={i}>
            {msg.role === "response" ? (
              <MarkdownResponse
                messageIndex={i}
                convo={context.current}
                feedback={context.feedback}
                sections={msg.sections}
                message={msg.content}
              />
            ) : (
              <UserMessage>{msg.content}</UserMessage>
            )}
          </div>
        ))}
        {context.current.messages.at(-1)?.role === "user" ? (
          <ResponseMessage>
            <div style={{ paddingBottom: 10 }}>
              <LoadingOutlined />
            </div>
          </ResponseMessage>
        ) : null}
      </ScrollArea.Viewport>
      <ScrollArea.Scrollbar
        className="ScrollAreaScrollbar"
        orientation="vertical"
      >
        <ScrollArea.Thumb className="ScrollAreaThumb" />
      </ScrollArea.Scrollbar>
      <ScrollArea.Scrollbar
        className="ScrollAreaScrollbar"
        orientation="horizontal"
      >
        <ScrollArea.Thumb className="ScrollAreaThumb" />
      </ScrollArea.Scrollbar>
    </ScrollArea.Root>
  );
}

function UserInputForm() {
  const context = useContext(chatContext);
  if (!context.current.doc) throw new Error("Expected Doc");

  const [question, setQuestion] = useState("");
  const loading = context.current.messages.at(-1)?.role === "user";
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        context.ask(question);
        setQuestion("");
      }}
      style={{ display: "flex", gap: 0, overflow: "hidden" }}
    >
      <Input
        size="large"
        autoFocus
        allowClear
        disabled={!context.current.doc || loading}
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
        placeholder="Query this document"
        style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
      />
      <Button
        type="primary"
        loading={loading}
        disabled={!context.current.doc || loading || !question}
        htmlType="submit"
        size="large"
        style={{
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        {!loading && (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_10_266)">
              <path
                d="M18.3334 1.66669L12.5001 18.3334L9.16675 10.8334M18.3334 1.66669L1.66675 7.50002L9.16675 10.8334M18.3334 1.66669L9.16675 10.8334"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_10_266">
                <rect width="20" height="20" fill="currentColor" />
              </clipPath>
            </defs>
          </svg>
        )}
      </Button>
    </form>
  );
}
