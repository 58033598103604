import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
} from "react";
import { useSearchParams } from "react-router-dom";
import { CharterType } from "../../api-clients/charlie";

export type UseCharlie = {
  tickers: string[];
  charterType: CharterType;
  process: (params: { tickers: string[]; charterType: string }) => void;
};

export const CharlieContext = createContext<UseCharlie>(
  undefined as unknown as UseCharlie
);

export const useCharlieContext = () => useContext(CharlieContext);

// useCharlie hook to access the context
export function useCharlie() {
  return useCharlieContext();
}

export function CharlieProvider(props: PropsWithChildren<{}>) {
  const [searchParams, setSearchParams] = useSearchParams();

  // Function to process the selected tickers
  const process = useCallback(
    ({
      tickers: newTickers,
      charterType,
    }: {
      tickers: string[];
      charterType: string;
    }) => {
      setSearchParams((prev) => {
        const prevTickers = prev.get("tickers")?.split(",") || [];
        const newSearchParams = new URLSearchParams(prev);
        newSearchParams.set(
          "tickers",
          [...new Set([...prevTickers, ...newTickers])].join(",")
        );
        newSearchParams.set("charterType", charterType);
        return newSearchParams;
      });
    },
    [setSearchParams]
  );

  return (
    <CharlieContext.Provider
      value={{
        tickers: searchParams.get("tickers")?.split(",") || [],
        charterType: (searchParams.get("charterType") ||
          "compensation_committee") as CharterType,
        process,
      }}
    >
      {props.children}
    </CharlieContext.Provider>
  );
}
