import { CharterType } from "../../../api-clients/charlie";

export const compensationComiteeCharterValidationData: Record<
  string,
  { companyName: string; industry: string; charterDocumentUrl: string }
> = {
  AES: {
    companyName: "AES Corporation, The",
    industry: "Utilities",
    charterDocumentUrl:
      "https://www.aes.com/sites/aes.com/files/2023-10/AES%20Compensation%20Committee%20Charter%202023.pdf",
  },
  APD: {
    companyName: "Air Products and Chemicals, Inc.",
    industry: "Materials",
    charterDocumentUrl:
      "https://www.airproducts.com/company/governance/board-of-directors/standing-committees/management-development-and-compensation-committee-charter",
  },
  LNT: {
    companyName: "Alliant Energy Corporation",
    industry: "Utilities",
    charterDocumentUrl:
      "https://investors.alliantenergy.com/files/doc_downloads/gov-docs/Compensation-Personnel-Committee.pdf",
  },
  AEE: {
    companyName: "Ameren Corporation",
    industry: "Utilities",
    charterDocumentUrl:
      "https://s21.q4cdn.com/448935352/files/doc_downloads/governance/2022/10/Human-Resources-Committee-Charter-(as-amended-Oct.-14-2022).pdf",
  },
  AAPL: {
    companyName: "Apple Inc.",
    industry: "Information Technology",
    charterDocumentUrl:
      "https://s2.q4cdn.com/470004039/files/doc_downloads/2023/08/peopleandcompensationcommitteecharter.pdf",
  },
  ANET: {
    companyName: "Arista Networks, Inc.",
    industry: "Information Technology",
    charterDocumentUrl:
      "https://s21.q4cdn.com/861911615/files/doc_downloads/2023/10/compensation-committee-charter_revised_2023.pdf",
  },
  AZO: {
    companyName: "AutoZone, Inc.",
    industry: "Consumer Discretionary",
    charterDocumentUrl:
      "https://about.autozone.com/static-files/4a14a570-5054-4b4b-b63e-928b458e1fe4",
  },
  BIIB: {
    companyName: "Biogen Inc.",
    industry: "Health Care",
    charterDocumentUrl:
      "https://investors.biogen.com/static-files/0fd9ae17-5a69-4666-accc-e0dfe354f111",
  },
  CDW: {
    companyName: "CDW Corporation",
    industry: "Information Technology",
    charterDocumentUrl:
      "https://s204.q4cdn.com/970314424/files/doc_downloads/committee/2023-12-14-compensation-committee-charter-approved-by-board-of-directors-2023-12-14.pdf",
  },
  COP: {
    companyName: "ConocoPhillips",
    industry: "Energy",
    charterDocumentUrl:
      "https://static.conocophillips.com/files/resources/hrcc-charter-10-09-2020.pdf",
  },
  COST: {
    companyName: "Costco Wholesale Corporation",
    industry: "Consumer Staples",
    charterDocumentUrl:
      "https://s201.q4cdn.com/287523651/files/doc_downloads/commcharts/10/amended-compensation-committee-charter_oct-2023.pdf",
  },
  DG: {
    companyName: "Dollar General Corporation",
    industry: "Consumer Staples",
    charterDocumentUrl:
      "https://ir-api.eqs.com/media/document/5bb2e766-33dc-4649-9a3b-3d68a1207ccd/assets/Charter--CHCM%20Committee%20(05-28-24%20Final).pdf?disposition=inline",
  },
  EA: {
    companyName: "Electronic Arts Inc.",
    industry: "Communication Services",
    charterDocumentUrl:
      "https://s204.q4cdn.com/701424631/files/doc_downloads/gov_doc/Compensation-Committee-Charter-August-2022-FINAL.pdf",
  },
  EOG: {
    companyName: "EOG Resources, Inc.",
    industry: "Energy",
    charterDocumentUrl:
      "https://www.eogresources.com/documents/comp_charter.pdf",
  },
  XOM: {
    companyName: "Exxon Mobil Corporation",
    industry: "Energy",
    charterDocumentUrl:
      "https://corporate.exxonmobil.com/corporate-governance/board-of-directors/compensation-committee-charter",
  },
  BEN: {
    companyName: "Franklin Resources, Inc.",
    industry: "Financials",
    charterDocumentUrl:
      "https://franklintempletonprod.widen.net/s/sx5gg6dljg/fri-compensation-committee-charter",
  },
  HRL: {
    companyName: "Hormel Foods Corporation",
    industry: "Consumer Staples",
    charterDocumentUrl:
      "https://s204.q4cdn.com/636391999/files/doc_downloads/governance/HRL-Compensation-Committee-Charter.pdf",
  },
  IPG: {
    companyName: "Interpublic Group of Companies, Inc., The",
    industry: "Communication Services",
    charterDocumentUrl:
      "https://www.interpublic.com/wp-content/uploads/2024/04/Compensation-Committee-Charter-7-27-23F.pdf",
  },
  IVZ: {
    companyName: "Invesco Ltd.",
    industry: "Financials",
    charterDocumentUrl:
      "https://www.invesco.com/content/dam/invesco/corporate/en/pdfs/regulatory/Compensation-Committee-Charter-May-2024.pdf",
  },
  IQV: {
    companyName: "IQVIA Holdings Inc.",
    industry: "Health Care",
    charterDocumentUrl:
      "https://s201.q4cdn.com/580005511/files/doc_downloads/committeeCharters/Final-LDCC-Charter.pdf",
  },
  KIM: {
    companyName: "Kimco Realty Corporation",
    industry: "Real Estate",
    charterDocumentUrl:
      "https://s1.q4cdn.com/944058265/files/Kimco-Executive-Compensation-Committee-Charter.pdf",
  },
  MTCH: {
    companyName: "Match Group, Inc.",
    industry: "Communication Services",
    charterDocumentUrl:
      "https://s203.q4cdn.com/993464185/files/doc_downloads/guidelines-committee/Compensation-and-Human-Resources-Committee-Charter-10-22-20.pdf",
  },
  MTD: {
    companyName: "Mettler-Toledo International Inc.",
    industry: "Health Care",
    charterDocumentUrl:
      "https://s202.q4cdn.com/258391627/files/doc_downloads/Compensation-Committee-Charter-2022-02-final.pdf",
  },
  NUE: {
    companyName: "Nucor Corporation",
    industry: "Materials",
    charterDocumentUrl:
      "https://assets.ctfassets.net/aax1cfbwhqog/23l0SFGVNV5XirRejPYe5u/80746be02fe0555234e03f7563b8c34f/Compensation_and_Executive_Development_Committee_Charter_-_12-15-23.pdf",
  },
  ORLY: {
    companyName: "O'Reilly Automotive, Inc.",
    industry: "Consumer Discretionary",
    charterDocumentUrl:
      "https://corporate.oreillyauto.com/corporate-governance-compensation-committee",
  },
  PYPL: {
    companyName: "PayPal Holdings, Inc.",
    industry: "Financials",
    charterDocumentUrl:
      "https://s201.q4cdn.com/231198771/files/doc_governance/2024/2024-compensation-committee-charter-approved.pdf",
  },
  O: {
    companyName: "Realty Income Corporation",
    industry: "Real Estate",
    charterDocumentUrl:
      "https://www.realtyincome.com/sites/realty-income/files/realty-income/corporate-governance/compensation-and-talent-committee-charter-approved-2022-02-15.pdf",
  },
  ROL: {
    companyName: "Rollins, Inc.",
    industry: "Industrials",
    charterDocumentUrl:
      "https://d1io3yog0oux5.cloudfront.net/_4b99a25d757c5c2e33ecf57b95572b22/rollins/db/867/8130/file/Human+Capital+Management+%26+Compensation+Committee+Charter+-+Final+02.14.2024.pdf",
  },
  STLD: {
    companyName: "Steel Dynamics, Inc.",
    industry: "Materials",
    charterDocumentUrl: "https://d2ghdaxqb194v2.cloudfront.net/2197/194319.pdf",
  },
  ULTA: {
    companyName: "Ulta Beauty, Inc.",
    industry: "Consumer Discretionary",
    charterDocumentUrl:
      "https://d1io3yog0oux5.cloudfront.net/_9104d84be3ce94c90626ba18541111b0/ulta/db/1923/17900/file/ULTA+Comp+Committee+Charter+Approved+2.20.2024.pdf",
  },
  WM: {
    companyName: "Waste Management, Inc.",
    industry: "Industrials",
    charterDocumentUrl:
      "https://investors.wm.com/static-files/2afb9fab-a5d5-4fbc-b115-c89fd32a4f1f",
  },
  XYL: {
    companyName: "Xylem Inc.",
    industry: "Industrials",
    charterDocumentUrl:
      "https://xyleminc.gcs-web.com/static-files/499ae492-d1a5-4aa9-aed9-4e2bee02cee5",
  },
  MMM: {
    companyName: "3M Company",
    industry: "Industrials",
    charterDocumentUrl:
      "https://d1io3yog0oux5.cloudfront.net/_5ac2248c65129d9a0609086eee37715d/3m/db/3209/29944/file/Compensation+and+Talent+Committee+Charter+%2802.06.2024%29+Final+%28clean%29.pdf",
  },
  AOS: {
    companyName: "A. O. Smith Corporation",
    industry: "Industrials",
    charterDocumentUrl:
      "https://www.aosmith.com/uploadedFiles/Web_Assets/Documents/Governance/Personnel%20and%20Compensation%20Committee%20Charter.pdf",
  },
  ABT: {
    companyName: "Abbott Laboratories",
    industry: "Health Care",
    charterDocumentUrl:
      "https://www.abbott.com/content/dam/corp/abbott/en-us/documents/pdfs/investors/compensation-committee-charter.pdf",
  },
  ABBV: {
    companyName: "AbbVie Inc.",
    industry: "Health Care",
    charterDocumentUrl:
      "https://investors.abbvie.com/static-files/0cacedc1-ddfe-406c-9945-11d5db34e526",
  },
  ACN: {
    companyName: "Accenture plc",
    industry: "Information Technology",
    charterDocumentUrl:
      "https://www.accenture.com/content/dam/accenture/final/capabilities/corporate-functions/legal/document/Accenture-Compensation-Culture-and-People-Committee-Charter-2022.pdf",
  },
  AFL: {
    companyName: "AFLAC Incorporated",
    industry: "Financials",
    charterDocumentUrl:
      "https://s24.q4cdn.com/367535798/files/doc_downloads/governance_committee/2020/Compensation-Committee-Charter-(2020).pdf",
  },
  ABNB: {
    companyName: "Airbnb, Inc.",
    industry: "Consumer Discretionary",
    charterDocumentUrl:
      "https://s26.q4cdn.com/656283129/files/doc_downloads/governance_doc_updated/Leadership-Development-Belonging-and-Compensation-Committee-Charter.pdf",
  },
  ALB: {
    companyName: "Albemarle Corporation",
    industry: "Materials",
    charterDocumentUrl:
      "https://s201.q4cdn.com/960975307/files/doc_downloads/gov-docs/committee-charters/2024/executive-compensation-talent-development-committee-charter.pdf",
  },
  ARE: {
    companyName: "Alexandria Real Estate Equities, Inc.",
    industry: "Real Estate",
    charterDocumentUrl:
      "https://www.are.com/disclosure/Compensation-Committee-Charter.pdf",
  },
  ALL: {
    companyName: "Allstate Corporation, The",
    industry: "Financials",
    charterDocumentUrl:
      "https://www.allstateinvestors.com/static-files/3d99e53e-579a-4e46-9bdd-338c50702ec4",
  },
  GOOG: {
    companyName: "Alphabet Inc.",
    industry: "Communication Services",
    charterDocumentUrl: "https://abc.xyz/investor/board-and-governance/ldicc/",
  },
  MO: {
    companyName: "Altria Group, Inc.",
    industry: "Consumer Staples",
    charterDocumentUrl:
      "https://www.altria.com/-/media/Project/Altria/Altria/about-altria/board-of-directors-and-corporate-governance/board-committees/charters/Compensation-Committee-Charter.pdf",
  },
  AMZN: {
    companyName: "Amazon.com, Inc.",
    industry: "Consumer Discretionary",
    charterDocumentUrl:
      "https://ir.aboutamazon.com/corporate-governance/documents-and-charters/leadership-development-and-compensation-committee/default.aspx",
  },
  AMCR: {
    companyName: "Amcor plc",
    industry: "Materials",
    charterDocumentUrl:
      "https://assets.ctfassets.net/f7tuyt85vtoa/2LNsvyujcQOUKa0684kS4S/245de7aadf3cdec53f3847d70d829790/D_CC__a__Compensation-Committee-Charter-Aug2024.pdf",
  },
  AEP: {
    companyName: "American Electric Power Company, Inc.",
    industry: "Utilities",
    charterDocumentUrl:
      "https://www.aep.com/assets/docs/investors/governance/HR_Charter.pdf",
  },
  AXP: {
    companyName: "American Express Company",
    industry: "Financials",
    charterDocumentUrl:
      "https://s26.q4cdn.com/747928648/files/doc_downloads/2023/12/CBC-Charter-12-6-2023-Final.pdf",
  },
  AMT: {
    companyName: "American Tower Corporation",
    industry: "Real Estate",
    charterDocumentUrl:
      "https://pardot.americantower.com/l/25692/2020-12-21/71lcnr/25692/17042288191gIuGPvz/AMT___Compensation_Committee_Charter_09_20_2023.pdf",
  },
  AWK: {
    companyName: "American Water Works Company, Inc.",
    industry: "Utilities",
    charterDocumentUrl:
      "https://s26.q4cdn.com/750150140/files/doc_downloads/governance/2023/2023-09-28_EDCC_Committee-Charter_as-of-September-28-2023.pdf",
  },
  APA: {
    companyName: "APA Corporation",
    industry: "Energy",
    charterDocumentUrl:
      "https://apacorp.com/wp-content/uploads/2022/05/APA_MDC_Committee_Charter-05.13.2022-2.pdf",
  },
  ADM: {
    companyName: "Archer-Daniels-Midland Company",
    industry: "Consumer Staples",
    charterDocumentUrl:
      "https://s1.q4cdn.com/365366812/files/doc_downloads/governance_documents/2024/2024-05-23-adm-compensation-and-succession-committee-charter-final.pdf",
  },
  T: {
    companyName: "AT&T Inc.",
    industry: "Communication Services",
    charterDocumentUrl:
      "https://investors.att.com/~/media/Files/A/ATT-IR-V2/committees-and-charters/human-resources-charter-12-2023.pdf",
  },
  BKR: {
    companyName: "Baker Hughes Company",
    industry: "Energy",
    charterDocumentUrl:
      "https://investors.bakerhughes.com/static-files/d78d6e6c-0fd2-4a08-9b5b-78d4604a6cc7",
  },
  CHTR: {
    companyName: "Charter Communications, Inc.",
    industry: "Communication Services",
    charterDocumentUrl:
      "https://ir.charter.com/static-files/966690c9-d456-46ff-a17b-6ba806580315",
  },
  CVX: {
    companyName: "Chevron Corporation",
    industry: "Energy",
    charterDocumentUrl:
      "https://www.chevron.com/-/media/chevron/investors/documents/managementcompensationcommitteecharter.pdf",
  },
  AMD: {
    companyName: "Advanced Micro Devices, Inc.",
    industry: "Information Technology",
    charterDocumentUrl:
      "https://d1io3yog0oux5.cloudfront.net/_568cc719bc48a0a5360d7eea6b2ea5d1/amd/db/721/6179/file/2024+CLRC+Charter+v1.pdf",
  },
  A: {
    companyName: "Agilent Technologies, Inc.",
    industry: "Health Care",
    charterDocumentUrl:
      "https://s24.q4cdn.com/305549747/files/doc_downloads/governance_documents/committee_charters/2022/05/Comp-Committee-Charter-approved-May-18-2022.pdf",
  },
  AKAM: {
    companyName: "Akamai Technologies, Inc.",
    industry: "Information Technology",
    charterDocumentUrl:
      "https://www.ir.akamai.com/static-files/f5337d00-ecb4-4e24-bb07-b9cfaf57020f",
  },
  ALGN: {
    companyName: "Align Technology, Inc.",
    industry: "Health Care",
    charterDocumentUrl:
      "https://investor.aligntech.com/static-files/77398080-c08f-47a4-b732-6969da29edfd",
  },
  ALLE: {
    companyName: "Allegion PLC",
    industry: "Industrials",
    charterDocumentUrl:
      "https://investor.allegion.com/corporate-governance/committee-composition/compensation-committee",
  },
  AAL: {
    companyName: "American Airlines Group Inc.",
    industry: "Industrials",
    charterDocumentUrl:
      "https://www.aa.com/content/images/customer-service/about-us/corporate-governance/aag-compensation-committee-charter.pdf",
  },
  AIG: {
    companyName: "American International Group, Inc.",
    industry: "Financials",
    charterDocumentUrl:
      "https://www.aig.com/content/dam/aig/america-canada/us/documents/about-us/legal/aig-compensation-and-management-resources-committee-charter.pdf.coredownload.pdf",
  },
  AMP: {
    companyName: "Ameriprise Financial, Inc.",
    industry: "Financials",
    charterDocumentUrl:
      "https://s25.q4cdn.com/634255556/files/doc_governance/2024/Oct/amp-compensation-and-benefits-committee-charter-10-02-2024.pdf",
  },
  AON: {
    companyName: "Aon plc",
    industry: "Financials",
    charterDocumentUrl:
      "https://assets.aon.com/-/media/files/aon/about/leadership-and-governance/occ-charter-9-18-20.pdf?_gl=1*1tc60m6*_gcl_au*ODQ5MjI3NzQyLjE3MjgyODYxMjk.*_ga*MTAxNzM5NjI0MS4xNzI4Mjg2MTMw*_ga_S2CXP61BY4*MTcyODI4NjEzMi4xLjEuMTcyODI4NjE0Mi4wLjAuMTMyNDUxMTM1Ng..*_fplc*ZVVnT1olMkZmcUtMVGdGaEkyU3pYJTJCdWlyMjR2bkM2cTYwN0tTSFJKdWk5NVVKWUkwRFRYRjJzMDVJbTdyWnJYM3FkbnBuZHBzeTVlcWUxS2ZqUjJBM085R2Z6RmVTbzB0UG0yNFZoQmllJTJGeXhDNEE0Y1pMbzh3Z3lDVmZ6aE5nJTNEJTNE",
  },
  APTV: {
    companyName: "Aptiv PLC",
    industry: "Consumer Discretionary",
    charterDocumentUrl:
      "https://s22.q4cdn.com/336558720/files/doc_downloads/governance_documents/2023/10/aptiv-compensation-and-human-resources-committee-charter-october-2023-final-10-31-2023.pdf",
  },
  CMS: {
    companyName: "CMS Energy Corporation",
    industry: "Utilities",
    charterDocumentUrl:
      "https://s26.q4cdn.com/888045447/files/doc_downloads/2020/01/CHR-Committee-Charter-2020-Final.pdf",
  },
  ADP: {
    companyName: "Automatic Data Processing, Inc.",
    industry: "Industrials",
    charterDocumentUrl:
      "https://s23.q4cdn.com/483669984/files/doc_downloads/doc_gov/Compensation-and-Management-Development-Committee-charter.pdf",
  },
  AVB: {
    companyName: "AvalonBay Communities, Inc.",
    industry: "Real Estate",
    charterDocumentUrl:
      "https://investors.avalonbay.com/_assets/_fa1bdb024a54205bafc070466eccaae1/avalonbay/db/2281/21536/file/comp-comm-charter-2-20-2023-final.pdf",
  },
  AVY: {
    companyName: "Avery Dennison Corporation",
    industry: "Materials",
    charterDocumentUrl:
      "https://www.investors.averydennison.com/static-files/c4785867-5f9e-4aa2-95d7-9e5f11d2dfc9",
  },
  BALL: {
    companyName: "Ball Corporation",
    industry: "Materials",
    charterDocumentUrl:
      "https://d1io3yog0oux5.cloudfront.net/_c0e7597d8a55315547fa1a87a525ff35/ball/db/940/9667/file/HR+Committee+Charter+-+updated+July+2024.pdf",
  },
  BBY: {
    companyName: "Best Buy Co., Inc.",
    industry: "Consumer Discretionary",
    charterDocumentUrl:
      "https://s204.q4cdn.com/864376893/files/doc_downloads/committee_charters/10/Compensation-and-Human-Resources-Committee-Charter-Sept-2024.pdf",
  },
  "BF.B": {
    companyName: "Brown-Forman Corporation",
    industry: "Consumer Staples",
    charterDocumentUrl:
      "https://www.brown-forman.com/sites/default/files/team_resources/2021-07/2021-01-27-Compensation-Committee-Charter-2021_FINAL_10.pdf",
  },
  BG: {
    companyName: "Bunge Global SA",
    industry: "Consumer Staples",
    charterDocumentUrl:
      "https://investors.bunge.com/governance/governance-document/human-resources-and-compensation-committee-charter",
  },
  CPT: {
    companyName: "Capital One Financial Corporation",
    industry: "Real Estate",
    charterDocumentUrl:
      "https://investor.capitalone.com/static-files/bce31926-4c75-4d25-972e-05e7c3e1cd66",
  },
  CNP: {
    companyName: "CenterPoint Energy, Inc.",
    industry: "Utilities",
    charterDocumentUrl:
      "https://investors.centerpointenergy.com/static-files/1c114353-814f-4dde-91d0-98fd185dabbf",
  },
  CMCSA: {
    companyName: "Comcast Corporation",
    industry: "Communication Services",
    charterDocumentUrl:
      "https://www.cmcsa.com/static-files/f5a40509-91d7-4851-b5c7-4780b96d1456",
  },
  CTRA: {
    companyName: "Coterra Energy Inc.",
    industry: "Energy",
    charterDocumentUrl:
      "https://www.coterra.com/wp-content/uploads/2023/11/CTRA-Compensation-Committee-Charter-vFinal.pdf",
  },
  DVN: {
    companyName: "Devon Energy Corporation",
    industry: "Energy",
    charterDocumentUrl:
      "https://dvnweb.azureedge.net/assets/documents/Sustainability/Governance/Corporate-Governance/DVN_BOD_Compensation-Committee-Charter.pdf",
  },
  FOXA: {
    companyName: "Fox Corporation",
    industry: "Communication Services",
    charterDocumentUrl:
      "https://media.foxcorporation.com/wp-content/uploads/prod/2023/11/17125001/Compensation-Committee-Charter-Last-Amended-November-2023.pdf",
  },
  LYV: {
    companyName: "Live Nation Entertainment, Inc.",
    industry: "Communication Services",
    charterDocumentUrl:
      "https://d1io3yog0oux5.cloudfront.net/_445afc81cac2f5d1fdb038024324a6ba/livenationentertainment/db/654/4655/file/Compensation+Committee+Charter.pdf",
  },
  ACGL: {
    companyName: "Arch Capital Group Ltd.",
    industry: "Financials",
    charterDocumentUrl:
      "https://www.archgroup.com/wp-content/uploads/Compensation-and-Human-Capital-Committee-Charter.pdf",
  },
  AJG: {
    companyName: "Arthur J. Gallagher & Co.",
    industry: "Financials",
    charterDocumentUrl:
      "https://s28.q4cdn.com/872121257/files/doc_downloads/committee_charters/2024/01/compensation-committee-charter-final.pdf",
  },
  BAX: {
    companyName: "Baxter International Inc.",
    industry: "Health Care",
    charterDocumentUrl:
      "https://www.baxter.com/sites/g/files/ebysai746/files/2018-04/cc-committee-charter.pdf",
  },
  BDX: {
    companyName: "Becton, Dickinson and Company",
    industry: "Health Care",
    charterDocumentUrl:
      "https://d1io3yog0oux5.cloudfront.net/_a83578a4ea48799a79cb46708e052807/bd/db/2434/24218/file/Compensation_Committee_Charter_-_July_2024_%28final%29.pdf",
  },
  BKNG: {
    companyName: "Booking Holdings Inc.",
    industry: "Consumer Discretionary",
    charterDocumentUrl:
      "https://s201.q4cdn.com/865305287/files/gov_documents/committee_doc/Compensation-Committee-Charter-(Oct-21-Update).pdf",
  },
  BWA: {
    companyName: "BorgWarner Inc.",
    industry: "Consumer Discretionary",
    charterDocumentUrl:
      "https://www.borgwarner.com/docs/default-source/investors/corporate-governance/compensation-committee-charter.pdf?sfvrsn=a56fcd3c_15",
  },
  BSX: {
    companyName: "Boston Scientific Corporation",
    industry: "Health Care",
    charterDocumentUrl:
      "https://investors.bostonscientific.com/~/media/Files/B/Boston-Scientific-IR-V3/legal/BSX_Executive_Compensation_and_HR_Committee_Charter_5-9-24.pdf",
  },
  AVGO: {
    companyName: "Broadcom Inc.",
    industry: "Information Technology",
    charterDocumentUrl:
      "https://investors.broadcom.com/static-files/7b59046f-4f13-416f-827e-4c5a3d6610e4",
  },
  BR: {
    companyName: "Broadridge Financial Solutions, Inc.",
    industry: "Industrials",
    charterDocumentUrl:
      "https://s203.q4cdn.com/209299927/files/doc_governance/2024/2024-broadridge-compensation-committee-charter.pdf",
  },
  BLDR: {
    companyName: "Builders FirstSource, Inc.",
    industry: "Industrials",
    charterDocumentUrl:
      "https://s202.q4cdn.com/867695273/files/doc_downloads/governance/charters/BLDR-Compensation-Committee-Charter-October-2022.pdf",
  },
  COF: {
    companyName: "CBRE Group, Inc.",
    industry: "Financials",
    charterDocumentUrl:
      "https://d1io3yog0oux5.cloudfront.net/_e4b2c744df653efbe93eadc6a6308c76/cbre/db/3593/33221/file/Compensation+Committee+Charter+%2811.15.2023%29.pdf",
  },
  CBRE: {
    companyName: "Celanese Corporation",
    industry: "Real Estate",
    charterDocumentUrl:
      "https://ir-api.eqs.com/media/document/8752a20a-19b5-4fe1-bc8f-4f57ff94f1e1/assets/Compensation%20Committee%20Charter%20July%202024%20(Approved).pdf?disposition=inline",
  },
  CE: {
    companyName: "CF Industries Holdings, Inc.",
    industry: "Materials",
    charterDocumentUrl:
      "https://s203.q4cdn.com/145805377/files/doc_governance/2024/Oct/20241003-compensation-and-management-development-committee-charter.pdf",
  },
  CF: {
    companyName: "Church & Dwight Co., Inc.",
    industry: "Materials",
    charterDocumentUrl:
      "https://s203.q4cdn.com/233583214/files/doc_downloads/gov/posted-Compensation-Human-Capital-Committee-Charter-final-as-revised-on-October-26-2022-1.pdf",
  },
  CHD: {
    companyName: "Cisco Systems, Inc.",
    industry: "Consumer Staples",
    charterDocumentUrl:
      "https://s2.q4cdn.com/951347115/files/doc_governance/12/Compensation-Committee-Charter-December-2022.pdf",
  },
  CSCO: {
    companyName: "Clorox Company, The",
    industry: "Information Technology",
    charterDocumentUrl:
      "https://www.thecloroxcompany.com/wp-content/uploads/2024/06/MDCC-Charter-May-2024.pdf",
  },
  CLX: {
    companyName: "Consolidated Edison, Inc.",
    industry: "Consumer Staples",
    charterDocumentUrl:
      "https://cdne-dcxprod-sitecore.azureedge.net/-/media/files/conedison/management-dev-and-comp-committee-charter.pdf?rev=575d18242bde42e791fc4bb5554ed032",
  },
};

export const charterValidationData: Record<
  CharterType,
  Record<
    string,
    { companyName: string; industry: string; charterDocumentUrl: string }
  >
> = {
  compensation_committee: compensationComiteeCharterValidationData,
  audit_committee: {},
  nominating_committee: {},
  board_governance: {},
  corporate_bylaws: {},
};
