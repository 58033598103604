import { useMutation } from "@tanstack/react-query";
import { createContext, useCallback, useMemo, useRef, useState } from "react";
import { saveFeedback } from "../../api-clients";
import { sendChatMessage } from "../../api-clients/rag";

export type UseChatApp = ReturnType<typeof useChatApp>;

export const chatContext = createContext(undefined as unknown as UseChatApp);

export function useChatApp() {
  const [active, setActive] = useState(0);
  const [chats, setChats] = useState<ChatInstance[]>([
    {
      id: 0,
      messages: [],
      doc: undefined,
    },
  ]);

  const open = useCallback(
    (doc: ChatDoc) => {
      if (chats.length === 1 && !chats[0].doc) {
        setChats((chats_) => [{ ...chats_[0], doc }]);
      } else {
        const found = chats.findIndex(
          (chat_) =>
            chat_.doc?.form === doc.form &&
            chat_.doc.ticker === doc.ticker &&
            chat_.doc.year === doc.year
        );
        if (found >= 0) setActive(found);
        else {
          let chatsCount = -1;
          setChats((chats_) => {
            chatsCount = chats_.length;
            return [
              ...chats_,
              {
                id: chats.length,
                messages: [],
                doc,
              },
            ];
          });
          setActive(chatsCount);
        }
      }
    },
    [chats]
  );

  const close = useCallback((doc: ChatDoc) => {
    setChats((chats_) => {
      if (chats_.length === 1) {
        return [
          {
            id: 0,
            messages: [],
            doc: undefined,
          },
        ];
      } else
        return chats_.filter(
          (chat_) =>
            chat_.doc?.ticker !== doc.ticker ||
            chat_.doc.form !== doc.form ||
            chat_.doc.year !== doc.year
        );
    });
    setActive(0);
  }, []);

  const message = useCallback(
    (message: ChatMessage, id = active) => {
      setChats((chats_) =>
        chats_.map((chat_) =>
          chat_.id === id
            ? { ...chat_, messages: [...chat_.messages, message] }
            : chat_
        )
      );
    },
    [active]
  );

  const current = chats[active];
  const session = useMemo(
    () => current.messages.map((msg) => msg.content),
    [current]
  );
  const ask = useCallback(
    (question: string) => {
      if (!current.doc || !question) return;
      const id = current.id;
      message({ role: "user", content: question }, id);
      return sendChatMessage(
        question,
        [
          {
            ticker: current.doc.ticker,
            form: current.doc.form,
            year: current.doc.year,
          },
        ],
        session
      ).then(
        ({
          sections: sectionsByDoc,
          answer: content,
          messageId,
        }) => {
          const sections = sectionsByDoc.at(0) || [];
          message(
            {
              role: "response",
              content,
              sections,
              feedback: { question, messageId, rating: undefined },
            },
            id
          );
        }
      );
    },
    [current, message, session]
  );

  const setChatsRef = useRef({ setChats });
  setChatsRef.current = { setChats };
  const feedback = useMutation({
    mutationFn: async (args: {
      rating: number;
      messageId: string;
      chatIndex: number;
      messageIndex: number;
    }) => {
      setChatsRef.current.setChats((chats_) =>
        chats_.map((chat_, i) => {
          if (i !== args.chatIndex) return chat_;
          return {
            ...chat_,
            messages: chat_.messages.map((msg, midx) => {
              if (midx !== args.messageIndex) return msg;
              return {
                ...msg,
                feedback: {
                  ...msg.feedback!,
                  rating: args.rating,
                },
              };
            }),
          };
        })
      );

      return saveFeedback(args.rating, args.messageId);
    },
  });

  return {
    open,
    close,
    list: chats,
    current,
    feedback,
    ask,
  };
}

export type ChatPrompt = string;
export type ChatDoc = {
  title: string;
  year: number;
  ticker: string;
  form: string;
};
export type ChatMessage = {
  role: "notice" | "user" | "response";
  content: string;
  sections?: {
    title: string;
    body: string;
    locator?: {
      startLocator?: string,
      endLocator?: string,
      startWord?: number,
      endWord?: number
    }
  }[];
  feedback?: {
    question: string;
    messageId: string;
    rating: number | undefined;
  };
};
export type ChatInstance = {
  id: number;
  prompt?: ChatPrompt;
  messages: ChatMessage[];
  doc?: ChatDoc;
};
