import { FormItem, Located } from "../../DynamicForm";

import { Flex } from "antd";

export const PbaMetrics = () => {
  return (
    <Flex gap={4} className="table-style">
      <Located path="metricName">
        <FormItem path="metricName.value" label="Metric Name" />
      </Located>
      <Located path="performance">
        <FormItem path="performance.value" label="Performance" />
      </Located>
      <Located path="weighting">
        <FormItem path="weighting.value" label="Weighting %" />
      </Located>
      <FormItem path="unit" label="Unit" />
      <FormItem path="corporateOrDivision" label="Corporate or Division" />
      <FormItem path="divisionName.value" label="Division Name" />

      {/*       <FormItem path="category" label="Category" />
      <FormItem path="relativeOrAbsolute" label="Relative or Absolute" />
      <FormItem path="fiscalYear" />
      <FormItem path="isFunding" />
      <FormItem path="isUmbrellaPlan" />
      <FormItem path="isModifier" />
      <FormItem path="isGrowth" />
      <FormItem path="isPerShareValue" />
      <FormItem path="isESG" /> */}
    </Flex>
  );
};


export const PbaMetricsDefinitions = () => {
  return (
      <Located path="">
        <FormItem path="metricName" label="Metric Name" />
        <FormItem path="actualMetricLanguage" label="Actual Metric Language" />
        <FormItem path="definition" label="Definition" />
        <FormItem path="category" label="Category" />
        <FormItem path="corporateOrDivision" label="Corporate or Division" />
        <FormItem path="relativeOrAbsolute" label="Relative or Absolute" />
        <FormItem path="unit" label="Unit" />
        <FormItem path="isFunding" label="Is Funding" />
        <FormItem path="isUmbrellaPlan" label="Is Umbrella Plan" />
        <FormItem path="isModifier" label="Is Modifier" />
        <FormItem path="isGrowth" label="Is Growth" />
        <FormItem path="isPerShareValue" label="Is Per Share Value" />
        <FormItem path="isESG" label="Is ESG" />
        <FormItem path="relativeToType" label="Relative to Type" />
        <FormItem path="relativeToName" label="Relative to Name" />
      </Located>
  );
};

export const PbaMetricsPerformances = () => {
  return (
    <Located path="">
      <FormItem path="applicableTo" label="Applicable To" />
      <FormItem path="metricName" label="Metric Name" />
      <FormItem path="performance" label="Performance" />
      <FormItem path="weighting" label="Weighting %" />
      <FormItem path="corporateOrDivision" label="Corporate or Division" />
      <FormItem path="divisionName" label="Division Name" />
      <FormItem path="fiscalYear" label="Fiscal Year" />
    </Located>
  )
};