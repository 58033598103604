import { FormItem, Located } from "../../DynamicForm"
import { ConfigProvider, Flex, Table } from "antd"
import { ReviewableCompensationPayout, ReviewableLTI, ReviewablePrinciple, ReviewableTargets } from "@chester/shared/def14a/cda/lti"
import { Review } from "../../../../api-clients/def14a"
import { valueOf } from "../../useReviews"

export const Principle = () => {
    return (
        <Located path="">
            <Flex gap={4} className="table-style">
                <FormItem path="kind" />
                <FormItem path="target" />
                <FormItem path="low" />
                <FormItem path="high" />
                <FormItem path="type" />
                <FormItem path="period" />
            </Flex>
        </Located>
    )
}

export const CompensationTargets = () => {
    return (
        <Located path="">
            <Flex gap={4} className="table-style">
                <FormItem path="kind" />
                <FormItem path="target" />
                <FormItem path="low" />
                <FormItem path="high" />
                <FormItem path="type" />
                <FormItem path="period" />
            </Flex>
        </Located>
    )
}   

export const CompensationPayout = () => {
    return (
            <Located path="">
                <Flex gap={4} className="table-style">
                <FormItem path="threshold" />
                <FormItem path="maximum" />
                <FormItem path="type" />
                <FormItem path="period" />
            </Flex>
        </Located>
    )
}

//-------------------------------------- for Clic table -- currently unused

type Value = number | undefined
const kinds = ["EIP", "NEIP", "Other", "Total"] as const
type Kind = typeof kinds[number]

const clicColumns= [
    {
        title: "Role",
        dataIndex: "role",
        key: "role",
        className: "clic-dark",
        fixed: "left"
    }, 
    {
        title: "Officer name",
        dataIndex: "name",
        key: "name",
        className: "clic-officer",
        fixed: "left"
    },
    {
        title: "Peer group",
        dataIndex: "peerGroup",
        key: "peerGroup",
    },
    {
        title: "Peer group(lo)",
        dataIndex: "peerGroupLow",
        key: "peerGroupLow",
    },
    {
        title: "Peer group(hi)",
        dataIndex: "peerGroupHigh",
        key: "peerGroupHigh",
    },
    {
        title: "% of peer median",
        dataIndex: "peerGroupMedianPercent",
        key: "peerGroupMedianPercent",
    },
    {
        title: "% of peer median(lo)",
        dataIndex: "peerGroupMedianPercentLow",
        key: "peerGroupMedianPercentLow",
    },
    {
        title: "% of peer median(hi)",
        dataIndex: "peerGroupMedianPercentHigh",
        key: "peerGroupMedianPercentHigh",
    },
    {
        title: "Salary",
        dataIndex: "salary",
        key: "salary",
    },
    {
        title: "Salary(lo)",
        dataIndex: "salaryLow",
        key: "salaryLow",
    },
    {
        title: "Salary(hi)",
        dataIndex: "salaryHigh",
        key: "salaryHigh",
    },
    {
        title: "LTI Target",
        dataIndex: "dollar",
        key: "dollar",
    },
    {
        title: "LTI Target(lo)",
        dataIndex: "dollarLow",
        key: "dollarLow",
    },
    {
        title: "LTI Target(hi)",
        dataIndex: "dollarHigh",
        key: "dollarHigh",
    },
    {
        title: "Payout threshold",
        dataIndex: "payoutThreshold",
        key: "payoutThreshold",
    },
    {
        title: "Payout maximum",
        dataIndex: "payoutMaximum",
        key: "payoutMaximum",
    },
    {title: "Type", dataIndex: "type", key: "type"},
] as const

type ClicRowField = typeof clicColumns[number]["dataIndex"]

type ClicRow = Record<ClicRowField, Value | Kind | string>

const identifier = (review: Review<any>) => {
    const {name, role} = review.datapointCoordinates
    return name || role
}


export const asClicRows = (reviews: Review<ReviewableLTI>[]): ClicRow[] => {
    const ret: ClicRow[] = []
    const identifiers = [...new Set(reviews.map(identifier))]
    for (const kind of kinds) {
        for (const id of identifiers) {
            const peopleReviews = reviews.filter(r => identifier(r) === id)
            if (peopleReviews.length === 0) continue
            const {name, role} = peopleReviews[0].datapointCoordinates
            const row: Partial<ClicRow> = {
                type: kind,
                name,
                role,
            }
            // TODO just taking the first one... :(
            // what should it be when there are multiple? do we ever expect that?
            const principles = kind === "Total" 
                ? peopleReviews
                    .filter(r => r.reviewed.item.schema.definitions?.["def14a.cda.lti.principle"])                
                    .at(0)
                : undefined
            // benchmarks only relate to the Total compensation
            if (principles) {
                const val = valueOf(principles) as ReviewablePrinciple
                if (val.kind === "% of peer group median") {
                    row.peerGroupMedianPercent = val.target
                    row.peerGroupMedianPercentLow = val.low
                    row.peerGroupMedianPercentHigh = val.high
                } else if (val.kind === "peer group percentile") {
                    row.peerGroup = val.target
                    row.peerGroupLow = val.low
                    row.peerGroupHigh = val.high
                }
            }

            const targets = peopleReviews
                .filter(r => r.reviewed.item.schema.definitions?.["def14a.cda.lti.targets"])
                .filter(r => valueOf(r).kind === kind) as Review<ReviewableTargets>[]
            const targetDollar = targets.filter(r => valueOf(r).unit === "dollar").at(0) 
            const targetPercent = targets.filter(r => valueOf(r).unit === "% of salary").at(0) 
            if (targetDollar) {
                row.dollar = valueOf(targetDollar).target
                row.dollarLow = valueOf(targetDollar).low
                row.dollarHigh = valueOf(targetDollar).high
            }
            if (targetPercent) {
                row.salary = valueOf(targetPercent).target
                row.salaryLow = valueOf(targetPercent).low
                row.salaryHigh = valueOf(targetPercent).high
            }

            const payouts = peopleReviews
                .filter(r => r.reviewed.item.schema.definitions?.["def14a.cda.lti.payouts"])
                .filter(r => valueOf(r).kind === kind)
                .at(0)
            if (payouts) {
                const val = valueOf(payouts) as ReviewableCompensationPayout
                row.payoutThreshold = val.threshold
                row.payoutMaximum = val.maximum
            }
            if (principles || targetDollar || targetPercent || payouts) {
                ret.push(row as ClicRow)
            }
        }
    }
    return ret
}

export const LtiClicTable = (props: {reviews: Review<any>[]}) => {
    const datasource = asClicRows(props.reviews)
    const columns = [...clicColumns]

    return (
        <ConfigProvider theme={clicTableTheme}>
            <div className = "clic">
            <Table bordered dataSource={datasource} columns={columns} pagination={false} scroll={{x: 'max-content'}} />
            </div>
        </ConfigProvider>
)
}

const clicTableTheme = {
    components: {
        Table: {
            cellPaddingBlock: 4,
            cellPaddingInline: 4,
            headerBorderRadius: 0,
            headerBg: "#007999",
            headerColor: "#ddd",
            headerSplitColor: "#0180a0",
            borderColor: "#ccc",
        },
        token: {
            fontFamily: 'Helvetica, Arial, sans-serif',
        }
    }
}