import { PropsWithChildren } from "react";
import { UserButton } from "@clerk/clerk-react";
import { Flex, theme } from "antd";
import {
  CheckCircleOutlined,
  FileTextOutlined,
  MessageOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

export function Page({ children }: PropsWithChildren) {
  const { token } = theme.useToken();
  const navigate = useNavigate();
  return (
    <Flex
      gap={8}
      vertical
      style={{
        height: "100%",
        padding: 8,
        backgroundColor: token.colorBgLayout,
      }}
    >
      <Flex justify="space-between" align="center">
        <div
          style={{
            color: token.colorTextSecondary,
            fontWeight: 600,
            paddingLeft: 24,
          }}
        >
          {process.env.REACT_APP_PROJECT_NAME}
        </div>
        <UserButton
          showName
          appearance={{ variables: { colorText: token.colorTextSecondary } }}
        >
          <UserButton.MenuItems>
            <UserButton.Action
              label="Document Chat App"
              onClick={() => navigate("/")}
              labelIcon={<MessageOutlined />}
            />
            <UserButton.Action
              label="document explorer"
              onClick={() => navigate("/explorer-tool")}
              labelIcon={<FileTextOutlined />}
            />
            <UserButton.Action
              label="SCT dev tool"
              onClick={() => navigate("/viewer-tool")}
              labelIcon={<FileTextOutlined />}
            />
            <UserButton.Action
              label="8k 5.02"
              onClick={() => navigate("/8k-502-tool")}
              labelIcon={<FileTextOutlined />}
            />
            <UserButton.Action
              label="reviewer"
              onClick={() => navigate("/reviewer")}
              labelIcon={<SearchOutlined />}
            />
            <UserButton.Action
              label="Progress Reports"
              onClick={() => navigate("/progress-reports")}
              labelIcon={<CheckCircleOutlined />}
            />
            <UserButton.Action
              label="Evaluation Tool"
              onClick={() => navigate("/eval-tool")}
              labelIcon={<FileTextOutlined />}
            />
          </UserButton.MenuItems>
        </UserButton>
      </Flex>
      <div
        style={{
          flex: 1,
          minHeight: 0,
          maxHeight: "100%",
          maxWidth: "100%",
          overflow: "visible",
          borderRadius: 5,
        }}
      >
        {children}
      </div>
    </Flex>
  );
}
