import { FormItem, Located } from "../../DynamicForm"
import { Flex } from "antd"

export const Consultant = () => {
    return (
        <Located path="">
            <Flex gap={4} className="table-style">
                <FormItem path="name" />
                <FormItem path="engagement" />
                <FormItem path="fee" />
                <FormItem path="fiscalYear" />
            </Flex>
        </Located>
    )
}
