import { createContext, useContext, useMemo, useState } from "react";
import { loggerContext } from "./useLogger";

export interface SearchResult {    
    startNode: Text;
    endNode: Text;
    startOffset: number;
    endOffset: number;
    text: string;
}

const isNodeVisible = (node: Node): boolean => {
    let element = node.parentElement;
    while (element) {
        const style = window.getComputedStyle(element);
        if (style.display === 'none') {
            return false;
        }
        element = element.parentElement;
    }
    return true;
};

const search = (doc: Node, searchTerm: string): SearchResult[] => {
    if (!searchTerm) return [];    
    // Get all text nodes in the document
    const walker = document.createTreeWalker(
        doc,
        NodeFilter.SHOW_TEXT,
        {
            acceptNode: (node: Node) => {
                return isNodeVisible(node) 
                    ? NodeFilter.FILTER_ACCEPT 
                    : NodeFilter.FILTER_REJECT;
            }
        }
    );

    const results: SearchResult[] = [];
    const lowerTerm = searchTerm.toLowerCase();
    
    // Build a sliding window of text nodes
    let textWindow: Text[] = [];
    let windowText = '';
    
    // Process each text node
    let currentNode: Text;
    while (true) {
        currentNode = walker.nextNode() as Text
        if (!currentNode) break
        textWindow.push(currentNode);
        windowText += currentNode.textContent || '';
        
        // Trim the window if it's gotten too large
        while (windowText.length > searchTerm.length * 2 && textWindow.length > 1) {
            const removedNode = textWindow.shift();
            if (removedNode) {
                windowText = windowText.slice((removedNode.textContent || '').length);
            }
        }
        
        // Search for matches in the current window
        const lowerWindow = windowText.toLowerCase();
        let position = 0;
        
        while (true) {
            const index = lowerWindow.indexOf(lowerTerm, position);
            if (index === -1) break;
            
            // Find which nodes contain the start and end of the match
            let currentLength = 0;
            let startNode: Text | undefined;
            let startOffset = 0;
            let endNode: Text | undefined;
            let endOffset = 0;
            
            for (const node of textWindow) {
                const nodeLength = (node.textContent || '').length;
                
                // Find start node and offset
                if (!startNode && currentLength + nodeLength > index) {
                    startNode = node;
                    startOffset = index - currentLength;
                }
                
                // Find end node and offset
                if (!endNode && currentLength + nodeLength >= index + searchTerm.length) {
                    endNode = node;
                    endOffset = index + searchTerm.length - currentLength;
                    break;
                }
                
                currentLength += nodeLength;
            }
            
            if (startNode && endNode) {
                results.push({
                    startNode,
                    endNode,
                    startOffset,
                    endOffset,
                    text: windowText.slice(index, index + searchTerm.length)
                });
            }
            
            position = index + 1;
        }
    }
    return results;
}

export type UseTextSearch = ReturnType<typeof useTextSearch>
export const textSearchContext = createContext(undefined as unknown as UseTextSearch)

export const useTextSearch = (elementId: string) => {   
    const {log} = useContext(loggerContext)

    const [current, setCurrent] = useState<number>()
    const [searchTerm, setSearchTerm] = useState<string>("")
    const [results, setResults] = useState<SearchResult[]>([])
    const onSearch = (value: string) => {
        const doc = document.getElementById(elementId)
        if (!doc) return
        setSearchTerm(value)
        const results = search(doc, value)
        setCurrent(0)
        setResults(results)
        log("docSearch", {newSearchTerm: value})
    }

    const total = useMemo(() => results.length, [results])

    const onNext = () => {
        if (total && current !== undefined) {
            setCurrent((current + 1) % total)
            log("tabSearch", {direction: "next"})
        }
    }

    const onPrev = () => {
        if (total && current !== undefined) {
            setCurrent((current - 1 + total) % total)
            log("tabSearch", {direction: "prev"})
        }
    }

    const onClear = () => {
        setCurrent(undefined)
        setResults([])
        setSearchTerm("")
        log("docSearch", {newSearchTerm: ""})
    }

    return {
        current,
        total,
        searchTerm,
        onSearch,
        onNext,
        onPrev,
        onClear,
        results
    }
}