import { FormItem, Located } from "../../DynamicForm"
import { Flex } from "antd"

export const Clawback = () => {
    return (
        <>
        <Flex gap={4}>
            <FormItem path="origin" />
        </Flex>
        <Located path="">

            <FormItem path="excerpt" text={true} />

        </Located>
        </>
    )
} 