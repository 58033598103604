import { useDocumentTitle } from "@custom-react-hooks/use-document-title";
import { Page } from "../../components/Page";
import { useChatApp, chatContext } from "./useChatApp";
import { ChatReadyScreen } from "./screens/ready";
import { ChatStartScreen } from "./screens/start";
import { usePostHog } from "src/hooks/usePostHog";

export function ChatAppPage() {
  const context = useChatApp();
  const title = `${process.env.REACT_APP_PROJECT_NAME} ${
    context.current.doc
      ? ` - ${context.current.doc.ticker} ${context.current.doc.form}`
      : ""
  }`;
  useDocumentTitle(title);

  const POSTHOG_KEY = "phc_rHnGzEdX4Mj6wmNC2rosFeWD9rHejBV6oZsugWvduvB"
  usePostHog(POSTHOG_KEY)
  
  return (
    <Page>
      <chatContext.Provider value={context}>
        {context.current.doc ? <ChatReadyScreen /> : <ChatStartScreen />}
      </chatContext.Provider>
    </Page>
  );
}
